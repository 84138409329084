export default function convertDateString(inputString) {
  if (inputString) {
    // Split the input string into month and year parts
    const [month, year] = inputString.split('-');
    // Use the Date constructor to create a new Date object for the first day of the given month and year
    const dateObj = new Date(`${month} 1, ${year}`);

    // Get the year, month, and day components of the date object as strings
    const yearString = dateObj.getFullYear().toString();
    const monthString = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const dayString = '01';
    if (isNaN(yearString) || isNaN(monthString)) return null;
    // Concatenate the year, month, and day strings into the desired format
    const outputString = `${yearString}-${monthString}-${dayString}`;
    // Return the output string
    return outputString;
  } else return null;
}
