import React from "react";

function RadioCheck({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4346 6.72487C11.5642 6.90376 11.5243 7.15386 11.3454 7.28348L6.99444 10.4364C6.84306 10.5461 6.63588 10.5363 6.49552 10.4128L4.5718 8.72037C4.40594 8.57445 4.38978 8.3217 4.5357 8.15584L5.06412 7.5552C5.21004 7.38934 5.46279 7.37317 5.62865 7.51909L6.59222 8.3668C6.73258 8.49029 6.93976 8.50008 7.09114 8.39038L10.4066 5.98788C10.5855 5.85826 10.8356 5.89819 10.9652 6.07707L11.4346 6.72487Z"
        fill="white"
      />
    </svg>
  );
}

export default RadioCheck;
