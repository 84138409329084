import styled from "@emotion/styled";
import { IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { HiMenu } from "react-icons/hi";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";
import themes from "../../themes";

const brand = getThemeName();

const Container = styled.div`
  background-color: ${themes(brand).palette.greys.primary2};
  display: none;
  position: sticky;
  @media screen and (max-width: 600px) {
    display: flex;
    padding: 2px 3px;
  }
`;

const MiddleArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-right: 25px;
`;

function MobileNav({ handleDrawerToggle }) {
  return (
    <Container>
      <IconButton
        sx={{
          display: { xs: "block", sm: "none" },
          alignSelf: "start",
          marginTop: "auto",
          marginBottom: "auto",
        }}
        color="primary"
        onClick={() => handleDrawerToggle()}
      >
        <HiMenu />
      </IconButton>
      <MiddleArea>
        <Typography
          fontSize={themes(brand).typography.myFontSizes.h4}
          noWrap
          color="primary"
          component="div"
          fontWeight={600}
          sx={{
            alignSelf: "center",
            '@media (max-width: 420px)': {
              fontSize: themes(brand).typography.myFontSizes.h6,
            },
          }}
        >
          {myFormDataStore.getCurrentSection().title}
        </Typography>
      </MiddleArea>
    </Container>
  );
}

export default observer(MobileNav);
