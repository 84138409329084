function getLeadId() {
  // const queryParamsString = window.location.search.split("?")[1];
  // const queryParams = getQueryParamsFromString(queryParamsString);
  // const keyValuePairs = getKeyValuePairsFromQueryParams(queryParams);

  const params = new URLSearchParams(window.location.search);

  // // get a specific parameter value
  // const paramValue = params.get("lead_id");
  // const leadId = keyValuePairs["lead_id"];
  return params.get("lead_id");
}

export default getLeadId;
