import { makeAutoObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import FormDataStoreConstants from "../components/constants/FormDataStoreConstants";
import { MedicareConstants } from "../components/constants/Medicare.constants";
import convertDateString from "../components/questionPage/convertDateString";
import Question from "../components/questionPage/Question";
import { fireEvent } from "../cra";
import {
  QuestionState,
  QuestionType,
  ScreenState,
  SectionState,
  StepState,
} from "../data/enums";
import getLeadId from "../hooks/getLeadIdFromUrl";
import { fetchUserNameFromUrl, fetchIsUATFromUrl } from "../hooks/queryParams";
import FormService from "../services/FormService";

interface Question {
  id: string;
  isVisible: boolean;
  answer: string | string[];
  title?: string;
  questionState: QuestionState;
  questionType: QuestionType;
  dropDown?: any;
  checkBox?: any;
  dependentOn?: string;
  dependencies?: any;
  limit?: number;
}

interface Screen {
  id: string;
  isVisible: boolean;
  dependentOn?: string;
  dependentAnswer?: string;
  isEnd?: boolean;
  questions: Question[];
  title?: string;
  state: ScreenState;
}

interface Step {
  stepId: string;
  videoUrl: string | null;
  aetnaVideoUrl?: string | null;
  mutualOfOmahaVideoUrl?: string | null;
  medicareassociationVideoUrl?: string | null;
  label: string;
  description?: string;
  currentQuestionIndex: number;
  stepState: StepState;
  isInfo?: boolean;
  isVisible: boolean;
  visited: boolean;
  transcript?: object;
  aetnaTranscript?: object;
  mutualOfOmahaTranscript?: object;
  hideQuestionBox: boolean;
  screens: Screen[];
}

interface Section {
  sectionId: string;
  sectionState: SectionState;
  title: string;
  currentStepIndex: number;
  isExpanded: boolean;
  steps: Step[];
  visited: boolean;
}

export class FormDataStore {
  // observables
  accordionData: Section[] = [
    {
      sectionId: uuidv4(),
      visited: true,
      sectionState: SectionState.WIP,
      title: "INTRODUCTION",
      currentStepIndex: 0,
      isExpanded: true,
      steps: [
        {
          stepId: "1.05",
          visited: true,
          videoUrl: "https://marketingfaq1.wistia.com/medias/x3q6855g8b",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/x3q6855g8b",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/5ga2svlphn",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/x3q6855g8b",
          label: "Welcome to our Online Medicare Learning Center",
          currentQuestionIndex: 0,
          stepState: StepState.WIP,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_105 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_105 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_105 },
          hideQuestionBox: false,
          screens: [
            {
              id: "1.05.1",
              isVisible: true,
              state: ScreenState.Pending,
              questions: [],
            },
          ],
        },
      ],
    },
    {
      sectionId: uuidv4(),
      visited: false,
      sectionState: SectionState.WIP,
      title: "HELP US NARROW YOUR OPTIONS",
      currentStepIndex: 0,
      isExpanded: false,
      steps: [
        {
          stepId: "2.05",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/a33nrpahj5",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/a33nrpahj5",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/8r9qyn5du6",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/a33nrpahj5",
          label: "Introduction",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_205 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_205 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_205 },
          hideQuestionBox: false,
          screens: [
            {
              id: "2.05.1",
              isVisible: true,
              state: ScreenState.Pending,
              questions: [],
            },
          ],
        },
        {
          stepId: "2.10",
          hideQuestionBox: false,
          visited: false,
          videoUrl: null,
          label: "Supplemental Questions",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isVisible: true,
          screens: [
            {
              id: "2.10.1",
              isVisible: true,
              state: ScreenState.Pending,
              title: "Are you currently enrolled in Medicare Part B?",
              questions: [
                {
                  id: "2.10.1",
                  isVisible: true,
                  answer: "",
                  title: "Select the option that best applies to you",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.DropdownBox,
                  dropDown: {
                    options: ["No", "Yes"],
                  },
                },
              ],
            },
            {
              id: "2.10.2",
              isVisible: false,
              title: "When does your Medicare Part B take effect?",
              dependentOn: "2.10.1",
              state: ScreenState.Pending,
              dependentAnswer: "No",
              questions: [
                {
                  id: "2.10.no",
                  answer: "",
                  isVisible: true,
                  title:
                    "If you aren't sure of the actual date, please make an estimate.",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.Date,
                },
              ],
            },
            {
              id: "2.10.3",
              isVisible: false,
              title: "Enter the date enrolled into Medicare Part B",
              dependentOn: "2.10.1",
              state: ScreenState.Pending,
              dependentAnswer: "Yes",
              questions: [
                {
                  id: "2.10.yes",
                  answer: "",
                  isVisible: true,
                  title:
                    "If you aren't sure of the actual date, please make an estimate.",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.Date,
                },
              ],
            },
            {
              id: "2.10.4",
              isVisible: true,
              state: ScreenState.Pending,
              title: "Are you currently covered by other health insurance?",
              questions: [
                {
                  id: "2.10.4",
                  isVisible: true,
                  answer: "",
                  title: "Select the option that best applies to you",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.DropdownBox,
                  dropDown: {
                    options: ["No", "Yes"],
                  },
                },
              ],
            },
            {
              id: "2.10.5",
              isVisible: false,
              state: ScreenState.Pending,
              title: "Please choose which type of insurance you currently have",
              dependentOn: "2.10.4",
              dependentAnswer: "Yes",
              questions: [
                {
                  id: "insuranceType",
                  title: "Choose your insurance type",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  isVisible: true,
                  dropDown: { options: MedicareConstants.getInsuranceTypes() },
                  answer: "",
                },
                {
                  id: "planType",
                  title: "Choose your plan type",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  isVisible: false,
                  dependentOn: "insuranceType",
                  dependencies: [
                    {
                      answers: [MedicareConstants.INSURANCE_TYPE.MS],
                      options: MedicareConstants.getPlanTypesMs(),
                    },
                    {
                      answers: [MedicareConstants.INSURANCE_TYPE.MA],
                      options: MedicareConstants.getPlanTypesMa(),
                    },
                    {
                      answers: [
                        MedicareConstants.INSURANCE_TYPE.Group,
                        MedicareConstants.INSURANCE_TYPE.IH,
                      ],
                      options: MedicareConstants.getPlanTypesIh(),
                    },
                  ],
                  dropDown: { options: [] },
                  answer: "",
                },
                {
                  id: "carrier",
                  title: "Who is your current health insurance provider?",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  dropDown: { options: FormDataStoreConstants.CARRIER_LIST },
                  answer: "",
                  isVisible: false,
                  dependentOn: "insuranceType",
                  dependencies: [
                    {
                      answers: [
                        MedicareConstants.INSURANCE_TYPE.MS,
                        MedicareConstants.INSURANCE_TYPE.MA,
                        MedicareConstants.INSURANCE_TYPE.Group,
                        MedicareConstants.INSURANCE_TYPE.Retiree,
                        MedicareConstants.INSURANCE_TYPE.Cobra,
                        MedicareConstants.INSURANCE_TYPE.IH,
                      ],
                      options: this.getCarrierList(),
                    },
                  ],
                },
              ],
            },
            {
              id: "2.10.1.spouse",
              isVisible: false,
              state: ScreenState.Pending,
              title:
                "Is your spouse/partner currently enrolled in Medicare Part B?",
              questions: [
                {
                  id: "2.10.1.spouse",
                  isVisible: true,
                  answer: "",
                  title: "Select the option that best applies to you",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.DropdownBox,
                  dropDown: {
                    options: ["No", "Yes"],
                  },
                },
              ],
            },
            {
              id: "2.10.2.spouse",
              isVisible: false,
              dependentOn: "2.10.1.spouse",
              state: ScreenState.Pending,
              dependentAnswer: "No",
              title:
                "When does your spouse/partner's Medicare Part B take effect?",
              questions: [
                {
                  id: "2.10.no.spouse",
                  answer: "",
                  isVisible: true,
                  title:
                    "If you aren't sure of the actual date, please make an estimate.",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.Date,
                },
              ],
            },
            {
              id: "2.10.3.spouse",
              isVisible: false,
              dependentOn: "2.10.1.spouse",
              state: ScreenState.Pending,
              dependentAnswer: "Yes",
              title:
                "Enter the date your spouse/partner enrolled into Medicare Part B",
              questions: [
                {
                  id: "2.10.yes.spouse",
                  answer: "",
                  isVisible: true,
                  title:
                    "If you aren't sure of the actual date, please make an estimate.",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.Date,
                },
              ],
            },
            {
              id: "2.10.4.spouse",
              isVisible: false,
              state: ScreenState.Pending,
              title:
                "Is your spouse/partner currently covered by other health insurance?",
              questions: [
                {
                  id: "2.10.4.spouse",
                  isVisible: true,
                  answer: "",
                  title: "Select the option that best applies to you",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.DropdownBox,
                  dropDown: {
                    options: ["No", "Yes"],
                  },
                },
              ],
            },
            {
              id: "2.10.5.spouse",
              isVisible: false,
              dependentOn: "2.10.4.spouse",
              dependentAnswer: "Yes",
              state: ScreenState.Pending,
              title:
                "Please choose which type of insurance your partner/spouse currently has",
              questions: [
                {
                  id: "insuranceType.spouse",
                  title: "Choose your spouse/partner's insurance type",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  isVisible: true,
                  dropDown: { options: MedicareConstants.getInsuranceTypes() },
                  answer: "",
                },
                {
                  id: "planType.spouse",
                  title: "Choose your spouse/partner's plan type",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  isVisible: false,
                  dependentOn: "insuranceType.spouse",
                  dependencies: [
                    {
                      answers: [MedicareConstants.INSURANCE_TYPE.MS],
                      options: MedicareConstants.getPlanTypesMs(),
                    },
                    {
                      answers: [MedicareConstants.INSURANCE_TYPE.MA],
                      options: MedicareConstants.getPlanTypesMa(),
                    },
                    {
                      answers: [
                        MedicareConstants.INSURANCE_TYPE.Group,
                        MedicareConstants.INSURANCE_TYPE.IH,
                      ],
                      options: MedicareConstants.getPlanTypesIh(),
                    },
                  ],
                  dropDown: { options: [] },
                  answer: "",
                },
                {
                  id: "carrier.spouse",
                  title: "Who is your spouse/partner's current health insurance provider?",
                  questionType: QuestionType.DropdownBox,
                  questionState: QuestionState.Pending,
                  dropDown: { options: FormDataStoreConstants.CARRIER_LIST },
                  answer: "",
                  isVisible: false,
                  dependentOn: "insuranceType.spouse",
                  dependencies: [
                    {
                      answers: [
                        MedicareConstants.INSURANCE_TYPE.MS,
                        MedicareConstants.INSURANCE_TYPE.MA,
                        MedicareConstants.INSURANCE_TYPE.Group,
                        MedicareConstants.INSURANCE_TYPE.Retiree,
                        MedicareConstants.INSURANCE_TYPE.Cobra,
                        MedicareConstants.INSURANCE_TYPE.IH,
                      ],
                      options: this.getCarrierList(),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          stepId: "2.15",
          hideQuestionBox: false,
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/9a4llxbwun",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/9a4llxbwun",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/aweck2epft",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/9a4llxbwun",
          label: "Option 1 vs Option 2",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isVisible: true,
          transcript: {
            text: FormDataStoreConstants.SECTION_215_1,
          },
          aetnaTranscript: {
            text: FormDataStoreConstants.SECTION_215_1,
          },
          mutualOfOmahaTranscript: {
            text: FormDataStoreConstants.SECTION_215_1,
          },
          screens: [
            {
              id: "2.15.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [
                {
                  id: "2.15.coverage_preference",
                  questionType: QuestionType.CheckBox,
                  questionState: QuestionState.Pending,
                  checkBox: {
                    options: ["Option 1", "Option 2"],
                  },
                  isVisible: true,
                  answer: "",
                },
                {
                  id: "2.15.carrier_preference",
                  questionType: QuestionType.MultiDropdownBox,
                  questionState: QuestionState.Pending,
                  dropDown: {
                    options: MedicareConstants.CARRIER_LIST,
                  },
                  isVisible: true,
                  answer: [],
                  limit: 5,
                },
                {
                  id: "2.15.other_carriers",
                  questionType: QuestionType.Chips,
                  questionState: QuestionState.Pending,
                  dependentOn: "2.15.carrier_preference",
                  dependencies: [
                    {
                      answers: ["Other"],
                    },
                  ],
                  isVisible: false,
                  answer: [],
                  limit: 5,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      sectionId: uuidv4(),
      visited: false,
      sectionState: SectionState.Pending,
      title: "EDUCATION",
      currentStepIndex: 0,
      isExpanded: false,
      steps: [
        {
          stepId: "3.05",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/ul6032262z",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/ul6032262z",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/sb4pi2zf5s",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/q78vasgbk5",
          label: "Education Introduction",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_305 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_305 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_305 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.05.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.10",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/viy1fo4wxf",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/viy1fo4wxf",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/wsxudehsgf",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/dva19yag8p",
          label: "Original Medicare (Parts A and B)",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_310 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_310 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_310 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.10.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.15",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/kogrsgviux",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/kogrsgviux",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/gym4m8nuog",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/kogrsgviux",
          label: "Medicare Advantage (Part C)",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_315 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_315 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_315 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.15.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.20",
          visited: false,
          isVisible: true,
          videoUrl: "https://marketingfaq1.wistia.com/medias/5lciu5s01l",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/5lciu5s01l",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/y3ubljjl73",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/5lciu5s01l",
          label: "Prescription Drug (Part D)",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          transcript: { text: FormDataStoreConstants.SECTION_320 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_320 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_320 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.20.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.25",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/p6hbkhq29t",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/p6hbkhq29t",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/lyola7pvun",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/p6hbkhq29t",
          label: "What Medicare Does NOT Cover",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_325 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_325 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_325 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.25.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.30",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/yvwsiteb5g",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/yvwsiteb5g",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/dexiw7vuc2",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/yvwsiteb5g",
          label: "Medicare Supplement vs Medicare Advantage",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_330 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_330 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_330 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.30.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.35",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/o5an1w1fzn",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/o5an1w1fzn",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/pqeijwj8wr",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/o5an1w1fzn",
          label: "Medicare Advantage",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_335 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_335 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_335 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.35.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.40",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/wybj5s34x4",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/wybj5s34x4",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/2iz6ax4ftb",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/wybj5s34x4",
          label: "Medicare Supplement",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_340 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_340 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_340 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.40.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.45",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/pi768p7zfe",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/pi768p7zfe",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/3qffzendli",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/pi768p7zfe",
          label: "Medicare Supplement vs Medicare Advantage Recap",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_345 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_345 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_345 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.45.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.50",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/coiiam7kl8",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/coiiam7kl8",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/1ywap54n4u",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/coiiam7kl8",
          label: "Medicare Supplement Plans",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_350 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_350 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_350 },
          isInfo: true,
          hideQuestionBox: false,
          screens: [
            {
              id: "3.50.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.55",
          hideQuestionBox: false,
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/xi28olflzq",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/xi28olflzq",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/nucd7iilzt",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/xi28olflzq",
          label: "Reaffirm Coverage Preference",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_355 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_355 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_355 },
          screens: [
            {
              id: "3.55.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [
                {
                  id: "3.55",
                  isVisible: true,
                  answer: "",
                  questionState: QuestionState.Pending,
                  questionType: QuestionType.CheckBox,
                  checkBox: {
                    options: [
                      "Option 1 – Medicare Advantage (Part C)",
                      "Option 2 – Medicare Supplement (Medigap)",
                    ],
                  },
                },
              ],
            },
          ],
        },
        {
          stepId: "3.60",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/w06njnyrjp",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/w06njnyrjp",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/s7xddkq3pj",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/w06njnyrjp",
          label: "Medicare Supplement Plan F",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_360 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_360 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_360 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.60.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.65",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/xohta6cdne",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/xohta6cdne",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/z3ihs3oum7",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/xohta6cdne",
          label: "Medicare Supplement Plan G",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_365 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_365 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_365 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.65.1",
              state: ScreenState.Pending,
              isVisible: true,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.70",
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/qkbdkj76ed",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/qkbdkj76ed",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/gm7j3b93dj",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/qkbdkj76ed",
          label: "Medicare Supplement Plan N",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_370 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_370 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_370 },
          hideQuestionBox: false,
          screens: [
            {
              id: "3.70.1",
              isVisible: true,
              state: ScreenState.Pending,
              questions: [],
            },
          ],
        },
        {
          stepId: "3.75",
          hideQuestionBox: false,
          visited: false,
          videoUrl: "https://marketingfaq1.wistia.com/medias/a2yct6ybt5",
          aetnaVideoUrl: "https://marketingfaq1.wistia.com/medias/a2yct6ybt5",
          mutualOfOmahaVideoUrl: "https://marketingfaq1.wistia.com/medias/s3l9do5vw2",
          medicareassociationVideoUrl: "https://marketingfaq1.wistia.com/medias/a2yct6ybt5",
          label: "Thank You",
          currentQuestionIndex: 0,
          stepState: StepState.Pending,
          isInfo: true,
          isVisible: true,
          transcript: { text: FormDataStoreConstants.SECTION_375 },
          aetnaTranscript: { text: FormDataStoreConstants.SECTION_375 },
          mutualOfOmahaTranscript: { text: FormDataStoreConstants.SECTION_375 },
          screens: [
            {
              id: "3.75.1",
              isVisible: true,
              state: ScreenState.Pending,
              questions: [],
            },
          ],
        },
      ],
    },
  ];
  _currentSectionIndex = 0;
  _currentStepIndex = 0;
  _currentScreenIndex = 0;
  _currentQuestionIndex = 0;
  _formProgressText = "";

  _m101ObjectName = "";
  _isFirstQuestionGettingSaved = false;

  isFirstQuestionGettingSaved() {
    return this._isFirstQuestionGettingSaved;
  }

  setIsFirstQuestionGettingSaved(status: boolean) {
    this._isFirstQuestionGettingSaved = status;
  }

  getM101ObjectName() {
    return this._m101ObjectName;
  }

  setM101ObjectName(objectName: string) {
    this._m101ObjectName = objectName;
    localStorage.setItem(getLeadId(), objectName);
  }

  constructor() {
    makeAutoObservable(this);
  }

  // getter and setters

  getCurrentSectionIndex() {
    return this._currentSectionIndex;
  }

  setCurrentSectionIndex(index: number) {
    if (index < 0 || index >= this.accordionData.length) {
      throw new Error("Invalid section index");
    }
    this._currentSectionIndex = index;
    this.setCurrentStepIndex(this.getCurrentSection().currentStepIndex);
  }

  getCurrentStepIndex() {
    return this._currentStepIndex;
  }

  setCurrentStepIndex(index: number) {
    if (index < 0 || index >= this.getCurrentSection().steps.length) {
      throw new Error("Invalid step index");
    }
    this.getCurrentSection().currentStepIndex = this.getCurrentStepIndex();
    this._currentStepIndex = index;
  }

  getCurrentScreenIndex() {
    return this._currentScreenIndex;
  }
  setCurrentScreenIndex(index: number) {
    this._currentScreenIndex = index;
  }

  getCurrentQuestionIndex() {
    return this._currentQuestionIndex;
  }

  setCurrentQuestionIndex(index: number) {
    if (index < 0) {
      throw new Error("Invalid question index");
    }
    this.getCurrentStep().currentQuestionIndex = this.getCurrentQuestionIndex();
    this._currentQuestionIndex = index;
  }

  getCurrentSection() {
    return this.accordionData[this.getCurrentSectionIndex()];
  }

  getCurrentStep() {
    return this.getCurrentSection().steps[this.getCurrentStepIndex()];
  }

  getCurrentScreen() {
    return this.getCurrentStep().screens[this.getCurrentScreenIndex()];
  }

  getCurrentQuestion() {
    return (
      this.getCurrentScreen().questions &&
      this.getCurrentScreen().questions.length > 0 &&
      this.getCurrentScreen().questions[this.getCurrentQuestionIndex()]
    );
  }

  getFormProgress() {
    let answeredSteps = 0;
    let visibleSteps = 0;

    for (const section of this.accordionData) {
      for (const step of section.steps) {
        if (step.stepId === "3.75") continue;
        if (step.isVisible) {
          visibleSteps++;
          if (step.stepState === StepState.Completed) {
            answeredSteps++;
          }
        }
      }
    }

    return visibleSteps === 0 ? 0 : (answeredSteps / visibleSteps) * 100;
  }

  getCarrierList() {
    const carrierList = [...MedicareConstants.CARRIER_LIST]; 
    return carrierList.splice(0, 49); 
  }
  
  checkIsOpen = () => {
    let isSectionExpanded = false;
    this.accordionData.map((data) => {
      if (data.isExpanded) isSectionExpanded = true;
    });
    if (isSectionExpanded) return true;
  };

  setCurrentStepState(stepState: StepState) {
    this.getCurrentStep().stepState = stepState;
  }

  setStepVisibility(
    sectionIndex: number,
    stepIndex: number,
    visibility: boolean
  ) {
    this.accordionData[sectionIndex].steps[stepIndex].isVisible = visibility;
  }

  // this function returns the next screen
  nextScreen(
    sectionIndex: number,
    stepIndex: number,
    screenIndex: number,
    ignoreVisibility: boolean
  ) {
    // Get current indexes for section, step, and screen
    const currentSectionIndex = sectionIndex;
    const currentStepIndex = stepIndex;
    const currentScreenIndex = screenIndex;

    // Calculate indexes for section, step, and screen for the next possible question
    let nextScreenSectionIndex = currentSectionIndex;
    let nextScreenStepIndex = currentStepIndex;
    let nextScreenScreenIndex = currentScreenIndex + 1;

    for (let i = nextScreenSectionIndex; i < this.accordionData.length; i++) {
      for (
        let j = nextScreenStepIndex;
        j < this.accordionData[i].steps.length;
        j++
      ) {
        const step = this.accordionData[i].steps[j];
        if (!ignoreVisibility && !step.isVisible) {
          continue;
        }
        for (
          let k = nextScreenScreenIndex;
          k < this.accordionData[i].steps[j].screens.length;
          k++
        ) {
          const screen = this.accordionData[i].steps[j].screens[k];
          if (ignoreVisibility || screen.isVisible) {
            return [i, j, k];
          } 
        }
        nextScreenScreenIndex = 0;
      }
      nextScreenStepIndex = 0;
    }
    return [-1, -1, -1];
  }

  updateIndices(sectionIndex: number, stepIndex: number, screenIndex: number) {
    this.setCurrentSectionIndex(sectionIndex);
    this.setCurrentStepIndex(stepIndex);
    this.setCurrentScreenIndex(screenIndex);
  }

  previousScreen() {
    // Get current indexes for section, step, and screen
    const currentSectionIndex = this.getCurrentSectionIndex();
    const currentStepIndex = this.getCurrentStepIndex();
    const currentScreenIndex = this.getCurrentScreenIndex();

    // Calculate indexes for section, step, and screen for the previous possible question
    let previousScreenSectionIndex = currentSectionIndex;
    let previousScreenStepIndex = currentStepIndex;
    let previousScreenScreenIndex = currentScreenIndex - 1;

    let updateJ = false;
    let updateK = false;
    for (let i = previousScreenSectionIndex; i >= 0; i--) {
      if (updateJ) {
        previousScreenStepIndex = this.accordionData[i].steps.length - 1;
        updateJ = false;
      }
      for (let j = previousScreenStepIndex; j >= 0; j--) {
        const step = this.accordionData[i].steps[j];
        if (step.isVisible && step.screens.length > 0) {
          if (updateK) {
            previousScreenScreenIndex = step.screens.length - 1;
            updateK = false;
          }
          for (let k = previousScreenScreenIndex; k >= 0; k--) {
            const screen = this.accordionData[i].steps[j].screens[k];
            if (screen.isVisible) {

              return [i, j, k];
            }
          }
          updateK = true;
        }
      }
      updateJ = true;
      updateK = true;
    }

    return [-1, -1, -1];
  }

  nextStep(sectionIndex: number, stepIndex: number, ignoreVisibility: boolean) {
    // Get current indexes for section and step
    const currentSectionIndex = sectionIndex;
    const currentStepIndex = stepIndex;

    // // Calculate indexes for section and step for the next possible question
    let nextStepSectionIndex = currentSectionIndex;
    let nextStepStepIndex = currentStepIndex + 1;

    for (let i = nextStepSectionIndex; i < this.accordionData.length; i++) {
      for (
        let j = nextStepStepIndex;
        j < this.accordionData[i].steps.length;
        j++
      ) {
        const step = this.accordionData[i].steps[j];
        if (!ignoreVisibility && !step.isVisible) {
          continue;
        } else {
          return [i, j];
        }
      }
      nextStepStepIndex = 0;
    }
    return [-1, -1];
  }

  /**
   * Store function to update _videoProgress with the status of the current step
   * @param isTypeSkip: boolean
   * @returns
   */
  updateFormProgressForCurrentStep(isTypeSkip: boolean) {
    var stepInfo = this.getCurrentStep().stepId + " " + this.getCurrentStep().label;

    if (!this._formProgressText.includes(stepInfo)) {
      if (isTypeSkip === false && this._formProgressText.includes(stepInfo + " Skipped; "))
        this._formProgressText.replace((stepInfo + " Skipped; "), (stepInfo + " Completed; "))
      else {
        stepInfo += isTypeSkip ? " Skipped; " : " Completed; ";
        this._formProgressText += stepInfo;
      }
    }
  }

  /**
   * Store function to update _videoProgress with the status of the current screen
   * @param isTypeSkip: boolean
   * @returns
   */
  updateFormProgressForCurrentScreen(isTypeSkip: boolean) {
    var screenInfo = this.getCurrentScreen().id + " " + this.getCurrentScreen().title;
    
    if (!this._formProgressText.includes(screenInfo)) {
      if(isTypeSkip === false && this._formProgressText.includes(screenInfo + " Skipped"))
        this._formProgressText.replace((screenInfo + " Skipped; "), (screenInfo + " Completed; "))
      else {
        screenInfo += isTypeSkip ? " Skipped; " : " Completed; ";
        this._formProgressText += screenInfo;
      }
    }
  }
  

  async newHandleSubmit(answers) {
    this.toggleScreensVisibility(answers);

    this.getCurrentStep().stepId === "2.10"
      ? this.updateFormProgressForCurrentScreen(false)
      : this.updateFormProgressForCurrentStep(false);

    this.getCurrentScreen().id === "3.55.1" &&
      this.setVisibilityForPlanFGN(
        answers[0] === "Option 2 – Medicare Supplement (Medigap)"
      );

    // find next screen
    const [nextSectionIndex, nextStepIndex, nextScreenIndex] = this.nextScreen(
      this.getCurrentSectionIndex(),
      this.getCurrentStepIndex(),
      this.getCurrentScreenIndex(),
      false
    );

    // set current step state if step changes
    (nextStepIndex !== this.getCurrentStepIndex() ||
      nextSectionIndex !== this.getCurrentSectionIndex()) &&
      this.setCurrentStepState(StepState.Completed);

    this.getCurrentScreen().state = ScreenState.Completed;

    // set answers
    this.getCurrentScreen().questions &&
      this.getCurrentScreen().questions.length > 0 &&
      this.getCurrentScreen().questions.forEach((question, i) => {
        question.answer = answers[i];
      });

    const data = {
      forUAT: fetchIsUATFromUrl(),
      medicare101VideoProgress: this._formProgressText
    };

    if (this.getCurrentScreen().id === "2.10.4")
      data["healthQuestionAnswer"] = this.getHealthInsuranceAnswer();

    if (this.getCurrentScreen().id === "2.10.4.spouse")
      data["spouseHealthQuestionAnswer"] =
        this.getSpouseHealthInsuranceAnswer();

    if (this.getCurrentScreen().id === "2.15.1")
      data["Carrier_Preference__c"] = this.getCarrierPreference().join(";");

    if (this.getCurrentScreen().id === "3.55.1")
      data["m101ConfirmedCoverage"] = this.getConfirmedCoveragePreference();

    if (this.getCurrentScreen().id === "2.15.1")
      data["m101FirstCoverage"] = this.getFirstCoveragePreference();

    if (
      this.getCurrentScreen().id === "2.10.2" ||
      this.getCurrentScreen().id === "2.10.3"
    ) {
        data["Medicare_Part_B_Date_Current__c"] = convertDateString(
          this.getMedicarePlanBCurrentDate()
        );
        data["isEffectiveDatePassed"] = true
      }

    if (this.getCurrentScreen().id === "2.10.5") {
      data["currentCoverageSelf"] = this.getInsuranceType();
      data["currentPlanTypeSelf"] = this.getPlanType();
      data["currentProviderSelf"] = this.getProvider();
    }

    if (
      this.getCurrentScreen().id === "2.10.2.spouse" ||
      this.getCurrentScreen().id === "2.10.3.spouse"
    )
      data["Medicare_Part_B_Date_Current__c_spouse"] = convertDateString(
        this.getSpouseMedicarePlanBCurrentDate()
      );

    if (this.getCurrentScreen().id === "2.10.5.spouse") {
      data["currentCoverageSpouse"] = this.getSpouseInsuranceType();
      data["currentPlanTypeSpouse"] = this.getSpousePlanType();
      data["currentProviderSpouse"] = this.getSpouseProvider();
    }

    // TODO: Update _formProgressText on SF via QT
    this.updateSalesforce(data);

    this.toggleAccordionSection(nextSectionIndex);
    this.fireQuestionResponseEvent(answers, false);

    // move to next question
    this.setCurrentSectionIndex(nextSectionIndex);
    this.setCurrentStepIndex(nextStepIndex);
    this.setCurrentScreenIndex(nextScreenIndex);
  }

  /**
   * Store function which takes as input the answers for the current screen, along with the submit type
   * and fires events for CRA accordingly
   * @param answers: any[]
   * @param isTypeSkip: boolean
   * @returns
   */
  fireQuestionResponseEvent(answers: any[], isTypeSkip: boolean) {
    const step = this.getCurrentStep();
    const screen = this.getCurrentScreen();
    // if no questions present on screen, return
    if (!screen.questions) return;
    // handle Supplemental Questions - Insurance Type
    if (screen.id === "2.10.5" || screen.id === "2.10.5.spouse") {
      screen.questions.forEach((question, index) => {
        const payload = {
          contentId: question.id,
          contentTitle: question.title,
          ...(!isTypeSkip && { answer: answers[index] }),
        };

        question.isVisible &&
          fireEvent(
            isTypeSkip ? "questionSkipped" : "questionAnswered",
            null,
            payload
          );
      });
      return;
      // Handle option 1 vs option 2
    } else if (screen.id === "2.15.1") {
      const questions = screen.questions;
      // Answer coverage preference
      const firstCoveragePreferencePayload = {
        contentId: questions[0].id,
        contentTitle: "First Coverage Preference",
        ...(!isTypeSkip && { answer: answers[0] }),
      };
      fireEvent(
        isTypeSkip ? "questionSkipped" : "questionAnswered",
        null,
        firstCoveragePreferencePayload
      );

      // Answer carrier preference
      const carrierPreferencePayload = {
        contentId: questions[1].id,
        contentTitle: "Carrier Preference",
        ...(!isTypeSkip && {
          answer: this.getCarrierPreferenceFromCarriersAndExtraCarriers(
            answers[1] as string[],
            answers[2] as string[]
          ).join(";"),
        }),
      };
      fireEvent(
        isTypeSkip ? "questionSkipped" : "questionAnswered",
        null,
        carrierPreferencePayload
      );
      return;
      // All other cases (where there is one question per screen)
    } else {
      const payload = {};
      payload["contentTitle"] = screen.title ? screen.title : step.label;
      payload["contentId"] = screen.id;
      payload["event"] = isTypeSkip ? "questionSkipped" : "questionAnswered";
      if (!isTypeSkip) payload["answer"] = answers[0];

      fireEvent(
        isTypeSkip ? "questionSkipped" : "questionAnswered",
        null,
        payload
      );
    }
  }

  toggleAccordionSection(nextSectionIndex: number) {
    const currentSectionIndex = this.getCurrentSectionIndex();

    if (nextSectionIndex !== currentSectionIndex) {
      this.getCurrentSection().isExpanded = false;
      this.accordionData[nextSectionIndex].isExpanded = true;
    }
  }

  updateSalesforce = async (userData) => {
    if(this.getM101ObjectName() === "") {
      const persistedM101Object = localStorage.getItem(getLeadId());
      if (persistedM101Object !== null) {
        this.setM101ObjectName(persistedM101Object);
      }
    }
    try {
      if (getLeadId()) {
        if (
          this.getM101ObjectName() === null ||
          this.getM101ObjectName() === undefined ||
          this.getM101ObjectName() === ""
        ) {
          this.setIsFirstQuestionGettingSaved(true);
          userData["leadId"] = getLeadId();
          const result: any = await FormService.updateQTSalesforceLead(
            userData
          );
          this.setM101ObjectName(result.data.data.M101ObjectName);
          this.setIsFirstQuestionGettingSaved(false);
        } else {
          userData["leadId"] = getLeadId();
          userData["m101Name"] = this.getM101ObjectName();
          userData["userName"] = fetchUserNameFromUrl()
            ? fetchUserNameFromUrl()
            : "";
          FormService.updateQTSalesforceLead(userData);
        }
      }
    } catch (err) {
      this.setIsFirstQuestionGettingSaved(false);
      console.log("Error when updating M101 Object in SF", err.message);
    }
  };

  handleSkip() {
    const screen = this.getCurrentScreen();
    const questions = screen.questions;
    const nextIndixes = this.nextStep(
      this.getCurrentSectionIndex(),
      this.getCurrentStepIndex(),
      false
    );
    this.getCurrentStep().stepState = StepState.Skipped;
    this.getCurrentScreen().state = ScreenState.Skipped;
    if (questions.length) {
      this.fireQuestionResponseEvent([], true);
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        if (!question.isVisible) continue;
        question.questionState = QuestionState.Skipped;
      }
    } else {
      fireEvent(
        "questionSkipped",
        {},
        {
          contentId: screen.id,
          contentTitle: this.getCurrentStep().label,
        }
      );
    }

    this.toggleAccordionSection(nextIndixes[0]);

    this.getCurrentStep().stepId === "2.10"
      ? this.updateFormProgressForCurrentScreen(true)
      : this.updateFormProgressForCurrentStep(true);

    this.setCurrentSectionIndex(nextIndixes[0]);
    this.setCurrentStepIndex(nextIndixes[1]);
    this.setCurrentScreenIndex(0);
  }

  setIsExpanded(sectionIndex, isExpanded) {
    this.accordionData[sectionIndex].isExpanded = isExpanded;
  }

  toggleExpanded = (index: number) => {
    this.accordionData[index].isExpanded =
      !this.accordionData[index].isExpanded;
  };

  setSectionState(sectionIndex: number, sectionState) {
    this.accordionData[sectionIndex].sectionState = sectionState;
  }

  setVisitedStep(sectionIndex: number, stepIndex: number, visited: boolean) {
    this.accordionData[sectionIndex].steps[stepIndex].visited = visited;
  }

  setVisitedSection(sectionIndex: number, visited: boolean) {
    this.accordionData[sectionIndex].visited = visited;
  }

  isSectionAccessible = (sectionIndex: number) => {
    const isVisited = this.accordionData[sectionIndex].visited;

    return isVisited;
  };

  isStepAccessible = (sectionIndex: number, stepIndex: number) => {
    const step = this.accordionData[sectionIndex].steps[stepIndex];

    const isAccessible = this.isSectionAccessible(sectionIndex) && step.visited;

    return isAccessible;
  };

  handleClickStep = (sectionIndex: number, stepIndex: number) => {

    // step from the accordion step index
    let step = this.accordionData[sectionIndex].steps[stepIndex];

    // find stepId after eliminating invisible steps
    const stepId = this.accordionData[sectionIndex].steps.filter(
      (step) => step.isVisible
    )[stepIndex].stepId;
    let newStepIndex = stepIndex;

    // update step if stepIds don't match
    step.stepId !== stepId &&
      this.accordionData[sectionIndex].steps.forEach((s, index) => {
        if (stepId === s.stepId) {
          step = s;
          newStepIndex = index;
        }
      });

    const stepAccessible = step.visited;
    const sectionAccessible = this.isSectionAccessible(sectionIndex);

    // if (stepAccessible && sectionAccessible) {
    this.setCurrentQuestionIndex(0);
    if (sectionIndex !== this.getCurrentSectionIndex()) {
      this.setCurrentSectionIndex(sectionIndex);
    }
    this.setCurrentStepIndex(newStepIndex);
    this.setCurrentScreenIndex(0);
  };

  updateFormProgressForThankYouPage = () => {
    this._formProgressText += " Thank You Page - Loaded; ";
  };

  collapseAll = () => {
    this.accordionData.map((data) => {
      if (data.isExpanded) data.isExpanded = !data.isExpanded;
    });
  };

  openAll = () => {
    this.accordionData.map((data) => {
      data.isExpanded = !data.isExpanded;
    });
  };

  getNextContent = () => {
    const nextStepIndices = this.nextStep(
      this.getCurrentSectionIndex(),
      this.getCurrentStepIndex(),
      false
    );
    try {
      return this.accordionData[nextStepIndices[0]].steps[nextStepIndices[1]]
        .label;
    } catch {
      return null;
    }
  };

  getQuestionById(questionId: string) {
    for (let i = 0; i < this.accordionData.length; i++) {
      const section = this.accordionData[i];

      for (let j = 0; j < section.steps.length; j++) {
        const step = section.steps[j];

        for (let k = 0; k < step.screens.length; k++) {
          const screen = step.screens[k];

          for (let l = 0; l < screen.questions.length; l++) {
            const question = screen.questions[l];
            if (question.id === questionId) return question;
          }
        }
      }
    }
  }

  getScreenById(screenId: string) {
    for (let i = 0; i < this.accordionData.length; i++) {
      const section = this.accordionData[i];

      for (let j = 0; j < section.steps.length; j++) {
        const step = section.steps[j];

        for (let k = 0; k < step.screens.length; k++) {
          const screen = step.screens[k];
          if (screen.id === screenId) return screen;
        }
      }
    }
  }

  getMedicarePlanBCurrentDate() {
    const question = this.getQuestionById("2.10.1");

    const dateQuestion = this.getQuestionById(
      question.answer === "Yes" ? "2.10.yes" : "2.10.no"
    );

    return dateQuestion.answer;
  }
  getSpouseMedicarePlanBCurrentDate() {
    const question = this.getQuestionById("2.10.1.spouse");

    const dateQuestion = this.getQuestionById(
      question.answer === "Yes" ? "2.10.yes.spouse" : "2.10.no.spouse"
    );

    return dateQuestion.answer;
  }

  getCarrierPreference() {
    const defaultCarriers = this.getQuestionById("2.15.carrier_preference");
    const CustomCarriers = this.getQuestionById("2.15.other_carriers");
    const carriers = defaultCarriers.answer as string[];
    const extraCarriers: string[] = CustomCarriers.answer as string[];
    return this.getCarrierPreferenceFromCarriersAndExtraCarriers(
      carriers,
      extraCarriers
    );
  }

  /**
   * Store method which combines carries and extraCarriers
   * and removes 'Other' value from the array if present
   * @param carriers: string[]
   * @param extraCarriers: string[]
   * @returns
   */
  getCarrierPreferenceFromCarriersAndExtraCarriers(
    carriers: string[],
    extraCarriers: string[]
  ) {
    const allCarriers = [];
    if (carriers.includes("Other")) {
      allCarriers.push(...carriers);
      allCarriers.push(...extraCarriers);
      allCarriers.splice(carriers.indexOf("Other"), 1);
    } else allCarriers.push(...carriers);
    return allCarriers;
  }

  getConfirmedCoveragePreference() {
    const question = this.getQuestionById("3.55");
    if (question.answer === "Option 1 – Medicare Advantage (Part C)")
      return "Medicare Advantage (Part C)";
    else if (question.answer === "Option 2 – Medicare Supplement (Medigap)")
      return "Medicare Supplement (Medigap)";
    else return "";
  }

  getFirstCoveragePreference() {
    const question = this.getQuestionById("2.15.coverage_preference");
    if (question.answer === "Option 1")
      return "Option 1 - Medicare Advantage (Part C)";
    else if (question.answer === "Option 2")
      return "Option 2 - Medicare Supplement (Medigap)";
    else return "";
  }

  getInsuranceType() {
    const screen = this.getScreenById("2.10.5");
    const question = this.getQuestionById("insuranceType");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }
  getSpouseInsuranceType() {
    const screen = this.getScreenById("2.10.5.spouse");
    const question = this.getQuestionById("insuranceType.spouse");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }

  getPlanType() {
    const screen = this.getScreenById("2.10.5");
    const question = this.getQuestionById("planType");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }
  getSpousePlanType() {
    const screen = this.getScreenById("2.10.5.spouse");
    const question = this.getQuestionById("planType.spouse");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }

  getProvider() {
    const screen = this.getScreenById("2.10.5");
    const question = this.getQuestionById("carrier");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }
  getSpouseProvider() {
    const screen = this.getScreenById("2.10.5.spouse");
    const question = this.getQuestionById("carrier.spouse");
    return screen.isVisible && question.isVisible ? question.answer : "";
  }

  getHealthInsuranceAnswer() {
    const question = this.getQuestionById("2.10.4");
    return question.isVisible ? question.answer : "";
  }

  getSpouseHealthInsuranceAnswer() {
    const question = this.getQuestionById("2.10.4.spouse");
    return question.isVisible ? question.answer : "";
  }

  getFormProgessText() {
    return this._formProgressText;
  }

  toggleQuestionsVisibility(answers) {
    const changedAnswerIndices = [];
    const questions = this.getCurrentScreen().questions;
    questions &&
      questions.forEach((question, index) => {
        const dependentQuestion = questions.findIndex(
          (q) => q.id === question.dependentOn
        );
        if (dependentQuestion === -1) {
          return;
        }
        let isVisible = false;
        question.dependencies.forEach((dep) => {
          dep.answers.forEach((depAnswer) => {
            if (depAnswer === answers[dependentQuestion]) {
              question.isVisible = true;

              !dep.options.includes(answers[index]) &&
                answers[index] &&
                changedAnswerIndices.push(index);

              question.dropDown.options = dep.options;
              isVisible = true;
            } else if (answers[dependentQuestion].includes(depAnswer)) {
              question.isVisible = true;
              isVisible = true;
            }
          });
        });
        if (!isVisible) question.isVisible = false;
      });
    return changedAnswerIndices;
  }

  getScreenByIndex(
    sectionIndex: number,
    stepIndex: number,
    screenIndex: number
  ): Screen {
    try {
      return this.accordionData[sectionIndex].steps[stepIndex].screens[
        screenIndex
      ];
    } catch (error) {
      console.log("Error :: ", error);
      return null;
    }
  }

  toggleScreensVisibility(answers) {
    if (
      !this.getCurrentScreen().questions ||
      !(this.getCurrentScreen().questions.length > 0)
    )
      return;

    let currentSectionIndex = this.getCurrentSectionIndex();
    let currentStepIndex = this.getCurrentStepIndex();
    let currentScreenIndex = this.getCurrentScreenIndex();
    while (currentSectionIndex !== -1) {

      const newIndices = this.nextScreen(
        currentSectionIndex,
        currentStepIndex,
        currentScreenIndex,
        true
      );
      currentSectionIndex = newIndices[0];
      currentStepIndex = newIndices[1];
      currentScreenIndex = newIndices[2];

      const nextScreen = this.getScreenByIndex(
        currentSectionIndex,
        currentStepIndex,
        currentScreenIndex
      );
      const dependentOn =
        nextScreen && nextScreen.dependentOn ? nextScreen.dependentOn : null;
      if (nextScreen && nextScreen.dependentOn && currentSectionIndex !== -1) {
        this.getCurrentScreen().questions.forEach((question, index) => {
          if (nextScreen.dependentOn === question.id) {
            // nextScreen.isVisible = nextScreen.dependentAnswer === answer;
            this.setScreenVisibility(
              currentSectionIndex,
              currentStepIndex,
              currentScreenIndex,
              nextScreen.dependentAnswer === answers[index]
            );
          }
        });
      }
    }
  }

  setScreenVisibility(
    sectionIndex: number,
    stepIndex: number,
    screenIndex: number,
    visibility: boolean
  ) {
    try {
      this.accordionData[sectionIndex].steps[stepIndex].screens[
        screenIndex
      ].isVisible = visibility;
    } catch (error) {
      console.log("setScreenVisibility Error ::", error);
      return null;
    }
  }

  makeSpouseSupplementalQuestionsVisible = () => {
    this.getScreenById("2.10.1.spouse").isVisible = true;
    this.getScreenById("2.10.4.spouse").isVisible = true;
  };

  getButtonId = () => {
    return `${this.getCurrentScreen().id}-${this.getCurrentStep().label}`;
  };

  isThankYouPage() {
    return this.getCurrentScreen().id === "3.75.1";
  }

  setVisibilityForPlanFGN(visibility: boolean) {
    this.setStepVisibility(2, 11, visibility);
    this.setStepVisibility(2, 12, visibility);
    this.setStepVisibility(2, 13, visibility);
  }

  /**
  gets the form progress summary for all the steps
  */
  getProgressSummary() {
    return this.accordionData
      .map((section) => {
        return section.steps
          .filter((step) => step.isVisible)
          .map((step) => {
            return step.screens.length > 1
              ? step.screens
                  .filter((screen) => screen.isVisible)
                  .map((screen) => {
                    return `${screen.id} ${screen.title} ${
                      screen.state === ScreenState.Completed
                        ? "Completed; "
                        : "Skipped;  "
                    }`;
                  })
                  .join("")
              : `${step.stepId} ${step.label} ${
                step.stepState === StepState.Completed
                  ? "Completed; "
                  : step.stepId === "3.75" ? this.isThankYouPage() 
                  ? "Completed; "
                  : "Skipped; "
                  : "Skipped; "
              }`;
          })
          .join("");
      })
      .join("");
  }

  /**
   * Store method to return the leadAppSubstage based on the current screen
   * @returns leadAppSubstage
   */
  getLeadAppSubStage() {
    return this.getCurrentStep().stepId + " - " + this.getCurrentStep().label;
  }

  getCurrentVideoProgress() {
    return this._formProgressText + this.getCurrentStep().stepId + " " + this.getCurrentStep().label + " Skipped; ";
  }
}

export const myFormDataStore = new FormDataStore();
