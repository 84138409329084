import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import getLeadId from "../../hooks/getLeadIdFromUrl";
import {
  fetchUserNameFromUrl,
  getQueryParamsAsKeyValuePairs,
  fetchIsUATFromUrl
} from "../../hooks/queryParams";
import PhoneIcon from "../../icons/phone_icon.svg";
import FormService from "../../services/FormService";
import { myFormDataStore } from "../../stores/FormDataStore";
import convertDateString from "../questionPage/convertDateString";
import Question from "../questionPage/Question";
import SkipSection from "../questionPage/SkipSection";
import Transcript from "../questionPage/Transcript";
import VideoSection from "../questionPage/VideoSection";
import { getContact } from "../../hooks/getContact";
import getThemeName from "../../hooks/useThemeFromUrl";

const PhoneIconContainer = styled.div`
  height: 15px;
`;

const CallSection = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 0px;
  padding: 0px 20px;
  column-gap: 2px;
  align-self: center;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 50px;
  // background-color: azure;
  min-width: 50vw;
  width: 100%;
  margin: 50px 200px;
  @media screen and (max-width: 1000px) {
    margin: 50px 50px;
  }
  @media screen and (max-width: 700px) {
    margin: 50px 10px;
  }
  @media screen and (max-width: 600px) {
    margin: 50px 20px;
  }
`;

const ToolbarCallDiv = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

function QuestionPage({
  handleDrawerToggle,
  isDrawerOpen,
  routeToQuotingTool,
}) {
  const qp = getQueryParamsAsKeyValuePairs();
  const params = new URLSearchParams(window.location.search);
  const brandName = params.has("leadBrand") ? params.get("leadBrand") : ''
  const [isLoading, setIsLoading] = React.useState(false);

  // behaviour can be smooth or auto
  const scrollToTopSection = (behaviour) => {
    window.scrollTo({ top: 0, behavior: behaviour, block: "start" });
  };
  useEffect(() => {
    scrollToTopSection("auto");
  }, []);
  useEffect(() => {
    myFormDataStore.isSkip && scrollToTopSection("auto");
  }, [myFormDataStore.isSkip]);

  useEffect(() => {
    scrollToTopSection("smooth");
  }, [myFormDataStore.getCurrentStep()]);

  const updateSalesForceLead = async (additionalData = {}) => {
    if(myFormDataStore.getM101ObjectName() === "") {
      const persistedM101Object = localStorage.getItem(getLeadId());
      if (persistedM101Object !== null) {
        myFormDataStore.setM101ObjectName(persistedM101Object);
      }
    }
    const answers = myFormDataStore.getCurrentScreen().questions.map((question) => question.answer);
    myFormDataStore.toggleQuestionsVisibility(answers);

    const data = {
      leadId: getLeadId(),
      userName: fetchUserNameFromUrl(),
      m101Name: myFormDataStore.getM101ObjectName(),
      Carrier_Preference__c: myFormDataStore.getCarrierPreference().join(";"),
      Medicare_Part_B_Date_Current__c: convertDateString(
        myFormDataStore.getMedicarePlanBCurrentDate()
      ),
      medicare101VideoProgress: myFormDataStore.getProgressSummary(),
      currentCoverageSelf: myFormDataStore.getInsuranceType(),
      currentPlanTypeSelf: myFormDataStore.getPlanType(),
      currentProviderSelf: myFormDataStore.getProvider(),
      m101ConfirmedCoverage: myFormDataStore.getConfirmedCoveragePreference()
        ? myFormDataStore.getConfirmedCoveragePreference()
        : "",
      m101FirstCoverage: myFormDataStore.getFirstCoveragePreference()
        ? myFormDataStore.getFirstCoveragePreference()
        : "",
      callbackStatus: qp.callbackStatus ? qp.callbackStatus : "",
      forUAT: fetchIsUATFromUrl(),
      ...additionalData,
    };
    if (qp.spouse === "yes") {
      data["currentCoverageSpouse"] = myFormDataStore.getSpouseInsuranceType();
      data["currentPlanTypeSpouse"] = myFormDataStore.getSpousePlanType();
      data["currentProviderSpouse"] = myFormDataStore.getSpouseProvider();
      data["Medicare_Part_B_Date_Current__c_spouse"] = convertDateString(
        myFormDataStore.getSpouseMedicarePlanBCurrentDate()
      );
    }

    if (
      myFormDataStore.getM101ObjectName() === null ||
      myFormDataStore.getM101ObjectName() === undefined ||
      myFormDataStore.getM101ObjectName() === ""
    )
    {
      const result = await FormService.updateQTSalesforceLead(data);
      myFormDataStore.setM101ObjectName(result.data.data.M101ObjectName);
    }
    else {
      FormService.updateQTSalesforceLead(data).then((data) => {
        console.log("Salesforce lead updated!");
      }).catch((error)=>{
        console.log('error while updating SF lead: ', error)
      });
    }
  };

  return (
    <PageContainer id="container">
      {myFormDataStore.getCurrentStep().videoUrl && <VideoSection />}

      <Question
        routeToQuotingTool={routeToQuotingTool}
        handleScrollToTopSection={scrollToTopSection}
        updateSalesForceLead={updateSalesForceLead}
        setIsLoading={setIsLoading}
      />

      {!myFormDataStore.isFirstQuestionGettingSaved() && (
        <SkipSection
          routeToQuotingTool={routeToQuotingTool}
          handleScrollToTopSection={scrollToTopSection}
          updateSalesForceLead={updateSalesForceLead}
          isLoading={isLoading}
        />
      )}
      {myFormDataStore.getCurrentStep().transcript && (
        <Transcript text={brandName === 'aetna-medicare'
          ? myFormDataStore.getCurrentStep().aetnaTranscript.text
          : brandName === 'mutual of omaha-medicare'
            ? myFormDataStore.getCurrentStep().mutualOfOmahaTranscript.text
            : myFormDataStore.getCurrentStep().transcript.text} />
      )}
      <CallSection>
        <Typography color="primary" fontWeight={500}>
          Speak with a Licensed Insurance Agent
        </Typography>
        <a href={getContact(getThemeName()).href} style={{ textDecoration: "none" }}>
          <ToolbarCallDiv>
            <PhoneIconContainer>
              <img style={{ height: "100%" }} src={PhoneIcon} />
            </PhoneIconContainer>
            <Typography color="secondary" fontWeight="bold">
              {getContact(getThemeName()).text}
            </Typography>
          </ToolbarCallDiv>
        </a>
      </CallSection>
    </PageContainer>
  );
}

export default observer(QuestionPage);
