import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/ms" element={<App />} />
        <Route exact path="/ma" element={<App />} />
        <Route exact path="/pdp" element={<App />} />
        <Route exact path="/me" element={<App />} />
        <Route exact path="/" element={<App />} />
        <Route
          exact
          path="*"
          element={
            <div>
              Oops! It seems like we can&apos;t find the form you&apos;re
              looking for. Don&apos;t worry, our health insurance options are
              real and available to help you protect your health and wellbeing.
            </div>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
