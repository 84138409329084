import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import aetnaFavicon from '../images/favicons/aetna-favicon.png'
import mutualOfOmahaFavicon from '../images/favicons/moo-favicon.ico'
import defaultFavicon from '../images/favicons/flag-favicon.png'
import devotedIcon from '../images/favicons/devoted-favicon.png'

function SEO({ lang, meta, keywords, title }) {
  const siteMetadata = {
    siteUrl: `https://qa-medicare-101.netlify.app/`,
    description: `Get Rates now`,
    author: `Elite Insurance Partners`,
  };

  const metaDescription = siteMetadata.description;
  
  const getFavicon = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const leadBrand = urlParams.get('leadBrand');
    if (leadBrand?.includes('aetna')) {
      return aetnaFavicon;
    } else if (leadBrand?.includes('omaha')) {
      return mutualOfOmahaFavicon;
    }
    else if (leadBrand?.includes('devoted')) {
      return devotedIcon;
    }
    return defaultFavicon;
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: `noindex`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            href: getFavicon(),
          },
          { rel: 'shortcut icon', href: getFavicon() },
        ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
