const FormDataStoreConstants = {
  SECTION_105: `
  Welcome to our Online Medicare Learning Center. Our videos focus on educating beneficiaries on all parts of Medicare. Medicare can be confusing, and not everyone has the same level of knowledge. <br/><br/>
  We've helped thousands of individuals make an educated decision on something that could impact their livelihood. You owe it to yourself to ensure you fully understand all your options and all the benefits you may be eligible for. <br/><br/>
  If at any time you need to go back, skip a section, or pause for notes, you can do so using the navigation buttons. <br/><br/>
  Our services are at no cost to you and won't affect your rate in any way. We have access to all major plans and carriers to fit your exact needs and budget. Whether you go directly to a carrier, a local agent, another brokerage, or us, the rates will always be the same. What makes us unique is that we compare plan options each year for you. <br/><br/>
  Medicare education is crucial, and this decision may impact the rest of your life. <br/><br/>
  Once complete, you will have the information needed to make the best decision for you! <br/><br/>
  HAPPY LEARNING! <br/>
  `,

  SECTION_205: `
  In this section, we will ask you a series of questions to help us narrow down the right plan for your specific needs and budget. <br/><br/>
  Many clients don’t realize that there could be more than 100 different plans and carrier options in certain counties. <br/><br/>
  This section should only take a minute or two and will help us guide you to the option that best fits your needs. <br/>
  `,

  SECTION_215_1: `
  There are two routes that you can choose when it comes to your Supplemental Medicare options. I’m going to give you a quick example of each, then, you pick which one will best fit your needs. <br/><br/>
  The first option has a low to no monthly premium, a network of physicians, co-payments, and co-insurance. In many cases, you need a referral to go see specialists. These plans are known as pay-as-you-go plans. <br/><br/>
  The second option has little-to-no out-of-pocket costs, no network, no referral restrictions, and a fixed monthly premium. These are known as peace of mind plans. Pay now, so you don't have to worry later. <br/><br/>
  Also, as I mentioned earlier, we have access to all major plans and all carriers. <br/><br/>
  Which of the two options would you prefer? <br/><br/>
  Are you interested in any specific carriers? <br/><br/>
  Please take a moment to select your preferred option and the carriers you are interested in, below. <br/>
  `,

  SECTION_305: `
  Just to make sure you’re making an informed decision, we’ll go over the basics of Medicare where I’ll explain the differences between the two options, and then help you enroll into the plan that fits your needs. <br/><br/>
  Go ahead and pause the video to grab a pen and a piece of paper that’s blank on both sides for notes. <br/><br/>
  At the top of the page write our company name, Elite Insurance Partners. Followed by our phone number. (844) 811 - 2386. <br/><br/>
  Go ahead and set that piece of paper aside and we will come back to it later. <br/><br/>
  Continue to the next section when you are ready. <br/>
  `,

  SECTION_310: `
  There are 4 parts to Medicare, which consist of Parts A, B, C, and D. Not to be confused with plans, parts are Medicare-specific, and plans are specific to Medigap. Let's start with Original Medicare Parts A and B. <br/><br/>
  Part A as in alpha, covers hospital inpatient care, skilled nursing facility, hospice, and home healthcare. Part A is what you or your spouse have been paying into while working your entire life. Most Medicare beneficiaries do not have a monthly premium for Part A. With Part A, there is a per-benefit period deductible of $1,600. <br/><br/>
  So, let’s say you fall and break your hip today, you would pay a deductible. Then, four days after you leave the hospital you have a heart attack. You must pay the $1,600 deductible again. Bringing your total to $3,200. <br/><br/>
  Now, on that sheet of paper, under our name, write Part A – Hospitals. Under that, write monthly premium is Zero dollars. Then below, write the deductible of $1,600. Next to that, write per-benefit period. <br/><br/>
  Part B as in bravo, handles your doctor’s visits, outpatient care, and durable medical equipment. Unlike Part A, where most don’t have to pay a monthly premium, Part B does have a monthly premium. That premium amount is based on your adjusted gross income. <br/><br/>
  There is also a calendar year deductible of $226 and a 20% co-insurance. <br/><br/>
  That means, on a bill of $100,000 for outpatient care like chemotherapy and radiation, 80% is covered under Medicare. You are responsible for the other 20%, which would be $20,000, plus the Part B deductible of $226 and any excess charges. <br/><br/>
  Excess charges can be up to 15% over Medicare's approved amount, but they are very rare and easy to avoid. Be proactive and ask the doctor or facility in advance if they charge excess charges. Again, they are really uncommon, but they can occur. <br/><br/>
  On your paper, under what you previously wrote, write Part B – Doctors/Specialist. Then underneath, write monthly premium of $164.90, an annual deductible of $226 and 20% co-insurance with no out-of-pocket maximum. <br/><br/>
  That might sound like a lot of money, which it is, but that’s why it’s smart to enroll in Supplemental Medicare coverage to fill all or a large portion of those costs. Original Medicare is great, but you don’t want to be stuck with huge out of pocket costs. <br/><br/>
  Let’s keep it moving! <br/>
  `,

  SECTION_315: `
  Part C, as in Charlie, also known as Medicare Advantage plans, are managed care plans that include Medicare Part A, Part B, and often Part D, which are Prescription Drug Plans. <br/><br/>
  These all-in-one plans frequently have a low to no monthly premiums and depending on the plans offered in your zip code can include additional benefits. <br/><br/>
  After enrolling into a Medicare Advantage plan, you may place your red, white, and blue Medicare card aside in a safe place and begin using your Medicare Advantage card when visiting your doctor or hospital. <br/><br/>
  While you don’t lose your Medicare Parts A and B, your Medicare Advantage plan begins paying for your services, leaving you with co-payments, co-insurance, and deductibles. <br/><br/>
  That’s just the basics of Medicare Advantage, we'll get into more detail in an upcoming section. <br/>
  `,

  SECTION_320: `
  Now the last of the four parts of Medicare is Part D. <br/><br/>
  Part D, as in Dog, also known as Medicare Prescription plans, help cover the cost of outpatient prescriptions. Part D is offered through private companies either as a stand-alone plan, for those enrolled in Original Medicare or as a benefit that may be included with your Medicare Advantage Plan. <br/><br/>
  These plans work with Original Medicare Part A and Part B. Each carrier and plan have its own formularies. Meaning copays for each prescription from one carrier to another may be different. Aside of copays, Part D plans also have coinsurance and deductibles. <br/><br/>
  It’s extremely important that you compare and shop these plans as prices will vary. Also, keep in mind a Part D plan you buy this year may not be the right plan for you next year. Formularies can change, So, unless you shop these plans each year, you typically won’t know. <br/><br/>
  Even if you don’t take any prescriptions, we still advise that you enroll in a Part D plan. If you don’t, there is a 1% penalty of the "national base premium" times the number of full, uncovered months you didn't have Part D or creditable coverage. 
  For example, if you go 24 months without Part D coverage and then you decide you need it, you will be charged 24% interest of the national average on the drug plan you choose for the rest of your life. <br/><br/>
  This wraps up the four Parts of Original Medicare. <br/>
  `,
  SECTION_325: `
  Though Medicare is great, unfortunately it doesn’t cover everything. Medicare does not deem dental, vision, and hearing as medically necessary, so they do not cover it. <br/><br/>
  While Medicare covers medical costs for Cancer, Heart Attack, and Stroke, there are non-medical costs such as experimental drugs that may not be covered. <br/><br/>
  Many oral chemo medications can have high costs upwards of $10,000 per month. Medicare nor Medicare Prescription Drug plans will cover these high costs. <br/><br/>
  We offer great umbrella solutions that will cover your dental, vision, and hearing services as well as cancer, heart attack, and stroke, all for about $1/day. <br/><br/>

  Here are items that Medicare does not cover:<br/>
  <ul>
  <li>Long-Term Care - also called Custodial Care</li>
  <li>Most Dental Care</li>
  <li>Eye Exams for prescription glasses</li>
  <li>Dentures</li>
  <li>Cosmetic Surgery</li>
  <li>Massage therapy</li>
  <li>Routine physical exams</li>
  <li>Hearing aids and exams for fitting them</li>
  <li>Concierge care - also called concierge medicine, retainer-based medicine, boutique medicine, platinum practice, or direct care</li>
  <li>Covered items or services you get from an opt-out doctor or other provider - except in the case of an emergency or urgent need</li>
  </ul>
  
  When ready, please continue on to the next section.
  `,
  SECTION_330: `
  Now that we have the basics out of the way, we’re going to go over those two Supplemental Medicare options that we referred to earlier. <br/><br/>
  Once you have Original Medicare Parts A & B, the two options to choose from are Medicare Advantage Plans or Medicare Supplement Plans. <br/><br/>
  We'll go over the differences for you in just a few minutes so that you can make an educated decision on which plan is best for you. <br/><br/>
  `,
  SECTION_335: `
  The first option of the two, are Medicare Advantage plans, also known as Part C. <br/><br/>
  Medicare Advantage plans are known for their low to no monthly premiums. You more than likely have received mailers or seen commercials related to Medicare Advantage plans saying $0 premium or that the plan is free. <br/><br/>
  In most cases the plan might have a $0 premium. However, it’s not really free. These plans have co-payments, co-insurance and or deductibles. If you have ever been on an HMO or PPO through a group employer plan, these are very similar to that. <br/><br/>
  These are managed care plans run by private insurance carriers not Medicare, which use doctor and hospital networks. Many require referrals, and again, they have co-payments co-insurance and or deductibles. <br/><br/>
  These plans however are required to have a maximum out-of-pocket. This is an amount assigned to each plan and represents the maximum you will pay for covered health care services. The maximum out-of-pocket amount varies by plan and carrier. <br/><br/>
  Please be sure that you compare those out-of-pocket costs if looking at one of these plans. These plans do, however, have some additional benefits that doesn’t come standard with Original Medicare or a Medicare Supplement. <br/><br/>
  Many Medicare Advantage plans include a Prescription Drug plan, also known as Part D. Some but not all also include things like fitness programs, gym memberships or discounts, and some vision, hearing, and dental services like routine checkup or cleanings. <br/><br/>
  Plans can also cover even more benefits. For example, some plans may offer coverage for services like transportation to doctor visits, over-the-counter drugs that Part D doesn’t cover, and services that promote your health and wellness. Check with the plan before you enroll to see what benefits it offers, if you might qualify, and if there are any limitations. <br/><br/>
  The number one complaint we hear from our Medicare Advantage clients is the networks and out-of-pocket costs for hospitalization. In many plans, the per day out-of-pocket costs run 
  anywhere from $250 to $350 each day for up to 5 days. <br/><br/>
  So, if you were in the hospital for 5 days and the plan had a Hospital Co-pay of 350 dollars per day, you would be responsible for 1,750 dollars. To offset some of those potential high costs for hospitalization, there are plans called Hospital Indemnity plans. These plans can pay all or a very large portion for about $1 per day. <br/><br/>
  Go ahead and flip over your note paper and draw a line down the middle of the page. On the top right-hand side, write down Medicare Advantage aka Part C.   <br/><br/>

  Under that, write down these bullet points:<br/>
  <ul>
  <li>Participating Networks</li> 
  <li>Referrals Needed (for Most Plans)</li>
  <li>Some Out-of-Pocket Costs</li>
  <li>Pays instead of Original Medicare</li> 
  <li>Low to No Premiums</li> 
  <li>Non-Standardized</li> 
  <li>Added Benefits not Covered by Original Medicare or Medigap</li> 
  </ul>
  
  Once you have those written down, please continue to the next section.<br/>
  `,
  SECTION_340: `
  The second option of the two is Medicare Supplement plans, also known as Medigap. <br/><br/>
  Most might know them as Plan F, Plan G, or Plan N. This is where Plans versus Parts come in. Parts are Medicare-specific, and Plans are Medigap-specific. With these plans, you have a fixed monthly premium. They cover all or a large portion of the Part A, deductible of $1,600, and the 20% Part B Co-insurance. <br/><br/>
  Remember the example of breaking your hip or having a heart attack? These plans will cover the cost of the Part A, deductible of $1,600. <br/><br/>
  If you only stuck with Original Medicare, you would be responsible for that Deductible. Don’t forget, the Part A deductible is a per-benefit period cost. <br/><br/>
  We also talked about the $100,000 Chemo and Radiation bill. Original Medicare Part B would cover 80%, and the Medigap plan would cover the remaining 20%. If you only kept Original Medicare Parts A and B, you would be responsible for the remaining 20%. Which in that example would be $20,000. Keep in mind there is no maximum out-of-pocket cost for Part B. <br/><br/>
  With these plans, there are NO networks, NO referrals, and you can go to ANY doctor or hospital that accepts Medicare across the country or US territories. Almost 98% of Doctors and Hospitals accept Medicare. <br/><br/>
  You have little-to-no out-of-pocket costs, and all plans are Standardized by Medicare. What that means is that Plan G with one carrier is no different than Plan G with another carrier. The only difference is price and company rating. This is exactly why you want to shop these plans with a brokerage like us. <br/><br/>
  There could be some instances where your doctor’s office will not accept the carrier you end up enrolling in. It's always good to ask if they accept Medicare. Again, 98% of Doctors and Hospitals accept Medicare, so, more than likely the answer is, yes. They don’t even have to know which carrier or Medigap plan that you have. Everything is billed through Medicare and then they bill your Medigap carrier. <br/><br/>

  Once again, please grab that piece of paper and on the top left side of the T chart, next to Medicare Advantage, write down Medicare Supplement aka Medigap. Under that, write down these bullet points: <br/>
  <ul> 
  <li>No Networks</li>
  <li>No Referrals</li>
  <li>Little to No Out-of-Pocket Costs</li>
  <li>Works with Original Medicare</li>
  <li>Fixed Monthly Premiums</li>
  <li>Standardized by Medicare</li>
  </ul>
  
  One thing to remember, Medigap plans do not cover Prescription Drugs. You will need to get a separate Part D plan for that. <br/><br/>
  That wraps up Medicare Supplements. In a later video we will discuss the different plans that you will have access to, like Plan F, Plan G and Plan N. <br/>
  `,
  SECTION_345: `
  With both Medicare Supplement and Medicare Advantage plans, you keep Original Medicare Part A & B. The difference between the two is that with Medicare Supplements, Original Medicare Parts A & B still cover their portions and the Medicare Supplement pays all or a large portion of the difference. <br/><br/>
  With Medicare Advantage, Original Medicare Parts A & B will no longer be responsible for your coverage and a private insurance carrier will now be responsible, but in both instances, you keep Original Medicare. <br/><br/>
  A common question among beneficiaries is that if Original Medicare doesn't pay anything, how are the private insurance carriers with Medicare Advantage plans paying for your coverage? <br/><br/>
  Well, those private insurance carriers get subsidized a large amount every month for each person that enrolls in a Medicare Advantage plan. Since Medicare isn’t paying the hospitals and doctors directly anymore, the carrier does it all! <br/><br/>
  A big misconception is that if you choose a Medicare Advantage plan, you don’t have to pay your Part B premium of $164.90 This is FALSE. Whether you stick with Original Medicare, enroll in a Medicare Supplement or a Medicare Advantage plan, you still must pay that Premium. <br/><br/>
  If you’re collecting Social Security, that amount will automatically be taken out of your monthly check. But if your Social Security was delayed, you will be billed quarterly. <br/><br/>
  If you have a Medicare Supplement plan, you will keep both your Medicare Card & your Medicare Supplement card in your purse or wallet. With a Medicare Advantage plan, you can place your Original Medicare card somewhere safe. You will only need your Medicare Advantage card when visiting your doctor or hospital. <br/>
  `,

  SECTION_350: `
  With Medicare Supplement plans there are 10 lettered plans available but, over 98% of beneficiaries enroll into one of three plans. Those plans are Plan F, Plan G, and Plan N. The other seven plans are still great, but many insurance carriers don’t price them competitively and there are not as many benefits as the other three plans. Once you learn about Plan F, Plan G, & Plan N, you will understand the other seven Plans better. <br/><br/>
  Just a reminder, Original Medicare Parts A & B and a Medicare Supplement plan are just for medical use and can't be used for Prescription Drugs. Prescription Drugs would be covered under Part D. <br/><br/>
  All but 3 states have these standardized plans. If you are in Massachusetts, Minnesota, or Wisconsin, you will have different plan names, but the coverages will be similar to Plans F, G, and N. <br/><br/>
  Go ahead and grab that piece of paper. Flip over to the side where you have the Medicare Supplements and Medicare Advantage comparison. Below where it says Medicare Supplement, write Plan F. <br/><br/>
  Once you have that written down, please continue to the next section. <br/>
  `,

  SECTION_355: `
  At this point, you should know how Original Medicare Parts A & B and Medicare Part D work. But most importantly, you should know the differences between Medicare Supplement and Medicare Advantage plans. <br/><br/>
  Before we went through Medicare education, I asked you to choose which option sounded like it would best fit your needs. <br/><br/>
  Option 1 was Medicare Advantage and Option 2 was Medicare Supplement. <br/><br/>
  Now that you’ve learned more about these two options, which one sounds best to you? <br/>
  `,
  SECTION_360: `
  Prior to January of 2020, Plan F was the number one choice for Medicare Supplement plans. We call Plan F the Full Coverage plan. Plan F covers the entire Part A deductible, the entire Part B deductible, and the entire 20% Part B co-insurance. <br/><br/>
  NO co-insurance, NO deductibles, and NO co-pays. It’s like the set it and forget it plan. You pay your monthly premium and have no out-of-pocket costs for your medical needs. <br/><br/>
  Plan F also has a High Deductible option. Out of the 10 plans, Plan F is typically going to be the most expensive plan. <br/><br/>
  As of January 1st, 2020, Medicare eliminated all first dollar coverage plans for new Medicare beneficiaries. This is now known as MACRA (Medicare Access and CHIP Reauthorization Act). What this means is that if you became Medicare eligible prior to January 1st, 2020, you are able to enroll in first dollar plans. Which is Plan F, High Deductible Plan F, and Plan C. If you had Medicare prior to January 2020, you were grandfathered into these plans, and you should always have access to them. If you became Medicare eligible after January 1st, 2020, those plans are no longer available to you. <br/><br/>
  The good news is, Plan G, High Deductible Plan G, and Plan N are still available. Even before that Act went into effect, many of our clients enrolled into Plan G or Plan N. <br/><br/>
  Now, on that piece of paper, under where you wrote Plan F - please write no out-of-pocket costs. <br/><br/>
  Once you have that written down, please continue to the next section. <br/>
  `,
  SECTION_365: `
  Even before MACRA came into effect, Plan G was and still is a very popular plan. We call Plan G the Greatest Value plan. It covers the exact same things as Plan F except the Part B deductible. <br/><br/>
  Plan G covers the entire Part A deductible and the entire 20% Part B co-insurance. There are NO co-pays, NO co-insurance, and the only deductible you have is the Part B deductible of $226, which is annual. Plan G also has a High Deductible option. <br/><br/>
  On that piece of paper, below Plan F, please write Plan G. Under that, write Part B Deductible of $226 per year. <br/><br/>
  The reason so many clients chose Plan G over Plan F is because the premium savings is typically greater than what the annual Part B deductible is. <br/><br/>
  I have included an example of the savings on the screen. Please review before continuing. <br/>
  `,
  SECTION_370: `
  With Plan F no longer being available for new Medicare clients after 2020, Plan N has become the second most popular plan, behind Plan G. We call Plan N, the Nice Compromise plan. <br/><br/>
  Like Plan G, with Plan N you still must pay the annual Part B deductible of $226. In addition, you will have a co-pay that could be up to $20 per office visit, plus any excess charges. You would also pay a co-pay of up to $50 at the Emergency Room, which is waived if admitted as an inpatient. However, there are NO co-pays at Urgent Care facilities. <br/><br/>
  Although rare, excess charges can still occur. An excess charge is a flat-rate charge of up to <br/><br/>
  15% on top of Medicare Assignment, which is the Medicare-approved amount for a procedure. <br/><br/>
  So, if you go to a doctor that accepts Medicare but does not accept Medicare Assignment, you can be responsible for up to an additional 15% over the amount approved by Medicare. Fortunately, less than 3% of doctors across the country charge excess charges. <br/><br/>
  On that piece of paper, below Plan G, please write Plan N. Under that, write Part B Deductible of $226 per year, up to $20 Co-pay for Doctors or Specialists, up to $50 Co-pay for Emergency Room, and Excess Charges for Doctors or Specialists. <br/><br/>
  Most of the time, Plan N will be more cost-effective than Plan F and Plan G. It starts to get non-cost-effective if you go to the doctor a lot. The co-pay of up to $20 can be charged each time. <br/><br/>
  I have included an example of the savings on the screen. You will see in my example that if you are someone who goes to the doctor only a couple of times a year, it makes sense. <br/><br/>
  However, if you go more than a couple of times a year, Plan G might be the direction you want to go <br/>
  `,

  SECTION_375: `
  Thank you for taking the time to watch and learn from our videos. We truly appreciate your support and hope that you found the information useful. Remember, education is a lifelong journey. Keep exploring, stay curious, and we'll see you in the next steps. <br/><br/>
  When you're ready please move on to our quoting tool. <br/>
  `,
};

export default FormDataStoreConstants;
