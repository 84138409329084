export enum QuestionState {
  Answered,
  Pending,
  Skipped,
}
export enum QuestionType {
  Date,
  CheckBox,
  TextBox,
  DropdownBox,
  MultiDropdownBox,
  Chips,
}

export enum StepState {
  Completed,
  Skipped,
  Pending,
  WIP,
}

export enum SectionState {
  Completed,
  Skipped,
  Pending,
  WIP,
}

export enum ScreenState {
  Completed,
  Skipped,
  Pending,
}
