import { Typography } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";
import React, { useEffect } from "react";
import "./ChipsAnswer.css";

function ChipsAnswer({
  chips,
  handleChipsChange,
  showError,
  chipsText,
  setChipsText,
  isInvalid,
  limit
}) {
  return (
    <div>
      <MuiChipsInput
        value={chips}
        onChange={(newChips) => {
          if (limit) {
            if(newChips.length <= limit)
              handleChipsChange(newChips);
          } else
            handleChipsChange(newChips);
        }}
        margin="dense"
        error={showError}
        label={"Preferred Carriers"}
        onInputChange={(input) => {
          setChipsText(input);
        }}
        inputValue={chipsText}
      />
      {showError && (
        isInvalid ? (<Typography color="red" sx={{ textAlign: "left" }}>
          Invalid Input
        </Typography>) : (
          <Typography color="red" sx={{ textAlign: "left" }}>
            Required Field
          </Typography>
        ))}
    </div>
  );
}

export default ChipsAnswer;
