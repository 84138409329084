import React from "react";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import themes from "../../../themes";
import getThemeName from "../../../hooks/useThemeFromUrl";

const brand = getThemeName() ? getThemeName() : '';

const Container = styled.div`
  background-color: ${themes(brand).palette.primary.main};
  font-size: 12px;
  padding: 30px;
  color: white;
  text-align: center;
  a {
    color: inherit;
  }
`;
const TextContainer = styled.a`
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

const FooterContent = styled.p`
  line-height: 18px;
  font-weight: 300;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

function Footer() {
  return (
    <Container>
      <p>
        ©{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://eliteinsurancepartners.com/"
        >
          {new Date().getFullYear()} Elite Insurance Partners
        </a>
        . All Rights Reserved.
      </p>
      <FooterContent>
        Owned by: Elite Insurance Partners LLC. This website is not connected with the federal government or
        the federal Medicare program. The purpose of this website is the solicitation of insurance.
        We do not offer every plan available in your area. Currently we represent {(brand.startsWith('aetna') || brand.startsWith('mutual') ||  brand.startsWith('devoted')) ? '1 organization ' : '26 organizations '}
        which offer {(brand.startsWith('aetna') || brand.startsWith('devoted')) ? '854' : brand.startsWith('mutual') ? '150' : '3,740'} products in your area. 
        Please contact Medicare.gov or 1-800-MEDICARE or your local State Health Insurance Program to get information on all of your options.&nbsp;
      </FooterContent>
      <p style={{ fontSize: '14px' }}>
        <TextContainer
          target="_blank"
          rel="noreferrer"
          href="https://eliteinsurancepartners.com/legal-disclosure/"
        >
          Legal Disclosure
        </TextContainer>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <TextContainer
          target="_blank"
          rel="noreferrer"
          href="https://eliteinsurancepartners.com/terms-and-conditions/"
        >
          Terms of Use
        </TextContainer>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <TextContainer
          target="_blank"
          rel="noreferrer"
          href="https://eliteinsurancepartners.com/privacy-policy/"
        >
          Privacy Policy&nbsp;
        </TextContainer>
      </p>
      <span style={{ fontSize: '12px', fontWeight: '300' }}> MULTI-PLAN_MedicareCompared.com_M </span>
    </Container>
  );
}

export default observer(Footer);
