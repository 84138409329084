import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { myFormDataStore } from "../../stores/FormDataStore";

const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.greys.primary1,
  border: `${theme.border.card}`,
  padding: "30px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  rowGap: "15px",
}));

function createMarkup(str) {
  return {
    __html: str,
  };
}

function Transcript({ text }) {
  return (
    <Container>
      <Typography
        textAlign="left"
        variant="h3"
        fontWeight={600}
        color="primary"
      >
        Transcript
      </Typography>
      <Typography textAlign="left" variant="body1">
        {<div dangerouslySetInnerHTML={createMarkup(text)} />}
      </Typography>
      {/* <ArrowButton sx={{ textTransform: "unset !important" }}>
        View More Details <NavigateNextIcon />
      </ArrowButton> */}
    </Container>
  );
}

export default observer(Transcript);
