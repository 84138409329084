import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import MyAccordion from "../components/common/Accordion";
import Header from "../components/common/Header/Header";
import MobileNav from "../components/common/MobileNav";
import MobileSubNav from "../components/common/MobileSubNav";
import ProgressIndicator from "../components/common/ProgressIndicator";
import QuestionPage from "../components/home/QuestionPage";
import SEO from "../components/Seo";
import Footer from "../components/common/Footer/Footer";
import { fetchBrandFromUrl } from "../hooks/queryParams";
import { Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const drawerWidth = 240;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const PageContent = styled.div`
  display: flex;
  width: "100%";
  min-height: 73.4vh;
  // background-color: beige;
`;

const LeftSection = styled.div`
  display: none;
  height: 100%;
  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileProgressIndicator = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
`;

const DrawerHeader = styled.div`
  padding: 20px 5px 20px 20px;
  display: flex;
  justify-content: space-between;
`;

function HomePage({ routeToQuotingTool }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCarrierSpecific, setIsCarrierSpecific] = useState(false);
  const [carrierName, setCarrierName] = useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCarrierName = (brandName) => {
    return brandName === 'aetna-medicare'
      ? 'Aetna'
      : brandName === 'mutual of omaha-medicare'
        ? 'Mutual of Omaha'
        : brandName === 'medicareassociation'
          ? 'medicareassociation'
          : brandName === 'devoted'
            ? 'Devoted Health'
            : '';
  }

  useEffect(()=> {
    const params = new URLSearchParams(window.location.search);
    const brandName = params.has("leadBrand") ? params.get("leadBrand") : ''
    if(brandName && (brandName.startsWith('aetna') || brandName.startsWith('mutual') || brandName.startsWith('medicareassociation') || brandName.startsWith('devoted'))){
      const carrierName =
          getCarrierName(brandName)

      setCarrierName(carrierName)
      setIsCarrierSpecific(true)
    }
  },[])

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box>
      <SEO title="Medicare 101" />
      <Header isCarrierSpecific={isCarrierSpecific} carrierName={carrierName}/>

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          //backgroundColor: "red",
          display: { xs: "block", sm: "none" },
          zIndex:10000,

          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader>
          <Typography fontWeight={600}>Sections</Typography> 
          <CloseIcon style={{paddingRight: '12px', width:'18px'}} onClick={handleDrawerToggle}/> 
        </DrawerHeader>
        <MyAccordion />
      </Drawer>

      <PageContainer>
        <MobileNav handleDrawerToggle={handleDrawerToggle} />
        <MobileSubNav />
        <MobileProgressIndicator>
          <ProgressIndicator />
        </MobileProgressIndicator>
        <PageContent>
          <LeftSection>
            <ProgressIndicator />
            <MyAccordion />
          </LeftSection>
          <QuestionPage
            handleDrawerToggle={handleDrawerToggle}
            isDrawerOpen={mobileOpen}
            routeToQuotingTool={routeToQuotingTool}
          />
        </PageContent>
        <Footer />
      </PageContainer>
    </Box>
  );
}

export default HomePage;
