import styled from "@emotion/styled";
import { AccordionSummary, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled as styledMui } from "@mui/material/styles";
// import { trace } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";
import { default as themes } from "../../themes";
import MyStepper from "./Stepper/Stepper";

const brand = getThemeName();

const Accordion = styledMui((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  textAlign: "left",
  ".MuiAccordionSummary-content": {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const IconWrapper = styled.div`
  height: 20px;
`;

const Container = styled.div`
  height: 100%;
  width: 300px;
  //background-color: beige;
`;

function MyAccordion() {
  let temp = myFormDataStore.accordionData.map((a, i) => {
    return i === 0 ? true : false;
  });

  return (
    <Container>
      {myFormDataStore.accordionData.map((section, i) => (
        <Accordion
          key={`accordion-${i}`}
          // expanded={i === myFormDataStore.currentSectionIndex}
          expanded={myFormDataStore.accordionData[i].isExpanded}
          sx={
            i === myFormDataStore.getCurrentSectionIndex()
              ? {
                  backgroundColor: themes(brand).palette.greys.primary2,
                }
              : {}
          }
        >
          <AccordionSummary
            onClick={() => {
              myFormDataStore.toggleExpanded(i);
              // myFormDataStore.moveToSection(i);
              //myFormDataStore.calculateCurrentStepIndexForSection(i);
            }}
          >
            <Typography
              fontWeight={600}
              fontSize={themes(brand).typography.myFontSizes.h5}
            >
              {section.title}
            </Typography>

            {myFormDataStore.accordionData[i].isExpanded ? (
              <IconWrapper>
                <MdKeyboardArrowUp style={{ height: "100%" }} />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <MdKeyboardArrowDown style={{ height: "100%" }} />
              </IconWrapper>
            )}
          </AccordionSummary>
          <MyStepper sectionIndex={i} />
        </Accordion>
      ))}
    </Container>
  );
}

export default observer(MyAccordion);
