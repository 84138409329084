import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";

function DropDownAnswer({
  options,
  label,
  value,
  handleChange,
  color,
  index,
  showValidationError,
  width,
}) {
  const question = myFormDataStore.getCurrentQuestion();
  const [currentVal, setCurrentVal] = useState(value);

  useEffect(() => {
    setCurrentVal(value);
  }, [value]);

  const onDropdownChange = (event) => {
    setCurrentVal(event.target.value);
    handleChange(event.target.value, question.questions && index);
  };

  return (
    <FormControl sx={{ minWidth: "100%" }}>
      <InputLabel
        sx={{
          fontSize: "14px",
          margin: "0px 0px 4px 0px",
          top: "-6px",
          "&.Mui-focused": {
            top: "0px",
          },
          "&.MuiInputLabel-shrink": {
            top: "0px",
          },
          color: showValidationError ? "red" : "grey",
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect
        labelId="demo-simple-select-label"
        id={myFormDataStore.getCurrentQuestion().title + "-select"} // TODO: question title
        label={label}
        color={color}
        value={currentVal || ""}
        defaultValue=""
        onChange={onDropdownChange}
        error={showValidationError}
        sx={{
          height: "40px",
          width: width,
          fontSize: "14px",
          borderRadius: "30px",
          textAlign: "left",
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '70%',
            },
          },
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
      </MuiSelect>
      {showValidationError && (
        <span style={{ color: "red", paddingTop: "5px", textAlign: "left" }}> Required Field</span>
      )}
    </FormControl>
  );
}
export default DropDownAnswer;
