import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";
import themes from "../../themes";

const brand = getThemeName();

const Container = styled.div`
  background-color: ${themes(brand).palette.greys.primary2};
  display: none;
  position: sticky;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 15px;
    border-top: 1px solid ${themes(brand).palette.greys.light};
    border-bottom: 1px solid ${themes(brand).palette.greys.light};
  }
`;

function MobileSubNav() {
  return (
    <Container>
      <Typography
        fontSize={themes(brand).typography.myFontSizes.h5}
        color="primary"
        component="div"
        fontWeight={600}
        align="left"
      >
        {myFormDataStore.getCurrentStep().label}
      </Typography>
      <Typography fontSize="myFontSizes.h5" color="greys.text">
        {myFormDataStore.getCurrentStep().stepId !== "3.75" &&
          `Up next: ${myFormDataStore.getNextContent()}`}
      </Typography>
    </Container>
  );
}

export default observer(MobileSubNav);
