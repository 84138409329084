import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";
import { myFormDataStore } from "../../../stores/FormDataStore";

function flattenChildren(children) {
  return React.Children.toArray(children).reduce((acc, child) => {
    if (React.isValidElement(child)) {
      return acc.concat(flattenChildren(child.props.children));
    } else {
      return acc.concat(child);
    }
  }, []);
}

const SkipVideoButton = styled(Button)(({ theme }) => ({
  display: "flex",
  columnGap: "0px",
  transition: "0.3s",
  "&:hover": {
    columnGap: "10px",
  },
  borderRadius: "9999px",
}));

function ArrowButton({ children, variant, sx, onClick, color }) {
  const textContent = flattenChildren(children).join(""); // flatten the children and extract the text content
  const fetchedId = textContent.replace(/\s/g, "-").toLowerCase();
  return (
    <SkipVideoButton
      color={color}
      onClick={onClick}
      sx={sx}
      variant={variant}
      id={fetchedId + "-arrow-button-" + myFormDataStore.getButtonId()}
    >
      {children}
    </SkipVideoButton>
  );
}

export default ArrowButton;
