import styled from "@emotion/styled";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CircularProgress, Typography } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import React  from "react";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";
import themes from "../../themes";
import ArrowButton from "../common/Buttons/ArrowButton";
import { fireEvent } from "../../cra";

const brand = getThemeName();

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0,0,0,0.85)",
    zIndex: "2000",
  },
};

const Container = styledMui(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const PopupDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  row-gap: 10px;
  position: relative;
`;

const PopupButtonsDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  display: flex;
  column-gap: 20px;

  @media screen and (max-width: 390px) {
    flex-direction: column;
  }
`;

const CloseIconDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

function SkipSection({
  handleScrollToTopSection,
  routeToQuotingTool,
  updateSalesForceLead,
  isLoading,
}) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [toQuotes, setToQuotes] = React.useState(false);
  const isThankYouPage =
    myFormDataStore.getCurrentStep().stepId === "3.75" ? true : false;

  const skipHandlers = {
    video: () => {
      updateSalesForceLead({
        medicare101VideoProgress: myFormDataStore.getCurrentVideoProgress()
      })
      handleScrollToTopSection("smooth");
      myFormDataStore.handleSkip();
    },
    toQuotes: () => {
      fireEvent("saveLeadDetail", {}, {});
      setTimeout(() => {
        updateSalesForceLead({
          leadAppStage: "Medicare 101",
          callbackLogic: myFormDataStore.isThankYouPage()
            ? "Completed M101 to Quotes"
            : "Skipped M101 to Quotes",
          leadAppSubstage:
            myFormDataStore.getCurrentScreen().id +
            " - " +
            myFormDataStore.getCurrentStep().label,
        });
        routeToQuotingTool();
      }, 1000);
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Container
      style={
        isThankYouPage
          ? {
              transform: "translate(0%, -50%)",
            }
          : {}
      }
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <PopupDiv>
          <CloseIconDiv onClick={() => closeModal()}>
            <MdClose />
          </CloseIconDiv>
          <Typography
            fontSize={themes(brand).typography.myFontSizes.h3}
            fontWeight="bold"
          >
            {toQuotes
              ? "Are you ready to view your Medicare plan options?"
              : "Are you sure you want to skip?"}
          </Typography>
          <Typography fontSize={themes(brand).typography.myFontSizes.h4}>
            {toQuotes
              ? "Reading through this section will help you understand Medicare better"
              : "Completing this section will help you better understand your Medicare options"}
          </Typography>
          <PopupButtonsDiv>
            <ArrowButton
              variant="contained"
              color="secondary"
              sx={{ 
                textTransform: "unset !important", 
                '@media (max-width: 390px)': {
                  marginBottom: '15px'
                },
              }}
              onClick={() => closeModal()}
            >
              <Typography fontSize={themes(brand).typography.myFontSizes.h6}>
                No, Stay Here
              </Typography>
            </ArrowButton>

            <ArrowButton
              variant="outlined"
              sx={{ textTransform: "unset !important" }}
              onClick={() => {
                if (!toQuotes) {
                  skipHandlers.video();
                  closeModal();
                  handleScrollToTopSection("auto");
                } else {
                  skipHandlers.toQuotes();
                }
              }}
            >
              <Typography fontSize={themes(brand).typography.myFontSizes.h6}>
                Yes,{" "}
                {toQuotes
                  ? "Show Me My Plans"
                  : myFormDataStore.getCurrentStep().videoUrl
                  ? "Skip Video"
                  : "Skip Section"}
              </Typography>
            </ArrowButton>
          </PopupButtonsDiv>
        </PopupDiv>
      </Modal>

      {myFormDataStore.getNextContent() && (
        <ArrowButton
          variant="text"
          sx={{ textTransform: "unset !important" }}
          onClick={() => {
            setToQuotes(false);
            openModal();
          }}
        >
          <Typography
            fontWeight="bold"
            style={{ paddingLeft: "2px" }}
            id={`${(myFormDataStore.getCurrentStep().videoUrl
              ? "Skip Video"
              : "Skip Section"
            )
              .replace(/\s/g, "-")
              .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
          >
            {myFormDataStore.getCurrentStep().videoUrl
              ? "Skip Video"
              : "Skip Section"}
          </Typography>

          <NavigateNextIcon
            id={`${(myFormDataStore.getCurrentStep().videoUrl
              ? "Skip Video"
              : "Skip Section"
            )
              .replace(/\s/g, "-")
              .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
          />
        </ArrowButton>
      )}
      {myFormDataStore.getNextContent() && (
        <Typography
          style={{ paddingLeft: "9px", paddingTop: "18px", textAlign: "left" }}
        >
          Up Next : {myFormDataStore.getNextContent()}
        </Typography>
      )}
      <br />

      <ArrowButton
        variant="text"
        color="secondary"
        sx={{ textTransform: "unset !important" }}
        onClick={() => {
          setToQuotes(true);
          if (isThankYouPage) skipHandlers.toQuotes();
          else openModal();
        }}
      >
        {isLoading ? (
          <CircularProgress color="inherit" disableShrink size={20} />
        ) : (
        <>
          <Typography
            fontWeight="bold"
            style={{
              paddingLeft: "2px",
              ...(isThankYouPage && { fontSize: "24px" }),
            }}
            id={`skip-to-quotes-arrow-button-${myFormDataStore.getButtonId()}`}
          >
            {isThankYouPage ? "Continue to Quotes" : "Skip to Quotes"}
          </Typography>
          <NavigateNextIcon
            id={`skip-to-quotes-arrow-button-${myFormDataStore.getButtonId()}`}
            sx={isThankYouPage && { fontSize: "32px" }}
          />
      </>
      )}
      </ArrowButton>
    </Container>
  );
}

export default observer(SkipSection);
