import axios from "axios";

const FormService = {
  updateSalesforceLead: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: "/api/updateSalesforceLead",
        data: leadData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          console.log("Form Service Success: ", resp);
          resolve(resp);
        })
        .catch((err) => {
          console.log("Form Service error: ", err);
          reject(err);
        });
    });
  },
  updateSalesforce101: (m101Data) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/updateSalesforceM101",
        data: m101Data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          console.log("Form Service Success from updateSalesforceM101: ", resp);
          resolve(resp);
        })
        .catch((err) => {
          console.log("Form Service error from updateSalesforceM101: ", err);
          reject(err);
        });
    });
  },
  insertSalesforce101: (m101Data) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/insertSalesforceM101",
        data: m101Data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          console.log("Form Service Success from insertSalesforceM101: ", resp);
          resolve(resp);
        })
        .catch((err) => {
          console.log("Form Service error from insertSalesforceM101: ", err);
          reject(err);
        });
    });
  },
  updateQTSalesforceLead: (leadData) => {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: "/api/updateQTSalesforceLead",
        timeout: 26000,
        data: leadData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((resp) => {
          console.log("Update M101 success for QT ", resp);
          resolve(resp);
        })
        .catch((err) => {
          console.log("Form Service error from updateQTSalesforceLead: ", err);
          reject(err);
        });
    });
  },
};

export default FormService;
