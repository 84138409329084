import { Autocomplete, Checkbox, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import "./MultiPickDropdownAnswer.css";
import styled from "@emotion/styled";

const DropdownContainer = styled.div`
  width: 350px;
  @media screen and (max-width: 780px) {
    width: 320px;
  }
  @media screen and (max-width: 680px) {
    width: 250px;
  }
  @media screen and (max-width: 600px) {
    width: 350px;
  }
  @media screen and (max-width: 430px) {
    width: 250px;
  }
  @media screen and (max-width: 330px) {
    width: 200px;
  }
`;

function MultiPickDropDownAnswer({
  selectedOptions,
  setSelectedOptions,
  showError,
  carrierName,
  options,
  limit,
}) {
  const [searchText, setSearchText] = useState("");

  const filterOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter(
      (option) => option.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    );
    if (!filteredOptions.includes("Other")) {
      filteredOptions.push("Other");
    }
    return filteredOptions;
  };

  const handleCarriersChange = (event, newSelectedCarriers) => {
    if (limit) {
      if(newSelectedCarriers.length <= limit) 
        setSelectedOptions(newSelectedCarriers);
    } else {
      setSelectedOptions(newSelectedCarriers);
    }
  };

  return (
    <FormControl
      sx={{
        width: "100%",
      }}
    >
      {selectedOptions && Array.isArray(selectedOptions) && (
        <DropdownContainer>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={handleCarriersChange}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                key={option}
                style={{
                  cursor:
                    !selected && selectedOptions.includes('No Preferred Carrier') ? 'not-allowed' :
                      !selected && limit && selectedOptions.length === limit
                        ? 'not-allowed'
                        : 'pointer',
                }}
                title={
                  !selected && selectedOptions.includes('No Preferred Carrier') ? 'Deselect “No Preferred Carrier” option to select a carrier' :
                    !selected && limit && selectedOptions.length === limit
                      ? 'You can select a maximum of 5 preferred carriers'
                      : ''
                }
              >
                <Checkbox
                  icon={<BsSquare />}
                  checkedIcon={<BsCheckSquareFill />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  disabled={!selected && selectedOptions.length === 5}
                />
                {option}
              </li>
            )}
            sx={{
              width: "100%",
              borderRadius: "20px",
            }}
            renderInput={(params) => (
              <TextField
                error={showError}
                {...params}
                label="Preferred Carriers"
                placeholder="Preferred Carriers"
                value={carrierName}
              />
            )}
            value={selectedOptions}
            inputValue={searchText}
            onInputChange={(event, newSearchText) => {
              setSearchText(newSearchText);
            }}
            filterOptions={filterOptions}
          />
        </DropdownContainer>
      )}
      {showError && (
        <span style={{ color: "red", paddingTop: "5px", textAlign: "left" }}>
          {" "}
          Required Field
        </span>
      )}
    </FormControl>
  );
}
export default MultiPickDropDownAnswer;
