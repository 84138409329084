import styled from "@emotion/styled";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CircularProgress, Typography } from "@mui/material";
import { styled as styledMui } from "@mui/material/styles";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { fireEvent } from "../../cra";
import { QuestionType } from "../../data/enums";
import { getQueryParamsAsKeyValuePairs } from "../../hooks/queryParams";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore";
import themes from "../../themes";
import ChipsAnswer from "../answerComponents/ChipsAnswer";
import DateAnswer from "../answerComponents/DateAnswer";
import DropDownAnswer from "../answerComponents/DropdownAnswer";
import McqAnswer from "../answerComponents/McqAnswer";
import MultiPickDropDownAnswer from "../answerComponents/MultiPickDropdownAnswer";
import ArrowButton from "../common/Buttons/ArrowButton";
import ErrorBoundary from "../common/ErrorBoundary";
const brand = getThemeName();

const Container = styledMui(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  backgroundColor: theme.palette.greys.primary1,
  // flexGrow: "1",
  border: `${theme.border.card}`,
  padding: "10px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",
}));

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 60px;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ScrollButtonContainer = styled.div`
  position: fixed;
  z-index: 1;
  font-size: 100%;
  bottom: 30px;
  right: 50px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0px;
  transition: 0.3s;
  cursor: pointer;
  justify-content: center;
  border-radius: 10px;
  border-style: solid;
  border-color: ${themes(brand).palette.primary.main};
  background-color: white;
  &:hover {
    row-gap: 5px;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const AnswerDiv = styled.div`
  flex: 1;
`;

function Question({
  handleScrollToTopSection,
  routeToQuotingTool,
  updateSalesForceLead,
  setIsLoading,
}) {
  const [answers, setAnswers] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? myFormDataStore
          .getCurrentScreen()
          .questions.map((question) => question.answer)
      : []
  );

  const [showValidationErrors, setShowValidationErrors] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? false
      : []
  );

  const [chipTexts, setChipTexts] = React.useState(
    myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
      ? myFormDataStore.getCurrentScreen().questions.map((question) => "")
      : []
  );

  const [isInvalid, setIsInvalid] = React.useState(false);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    myFormDataStore.getCurrentStep().visited = true;
    myFormDataStore.getCurrentSection().visited = true;
  }, [
    myFormDataStore.getCurrentStepIndex(),
    myFormDataStore.getCurrentSectionIndex(),
  ]);
  useEffect(() => {
    setAnswers(
      myFormDataStore.getCurrentScreen().questions &&
        myFormDataStore.getCurrentScreen().questions.length > 0
        ? myFormDataStore
            .getCurrentScreen()
            .questions.map((question) => question.answer)
        : []
    );
    setShowValidationErrors(
      myFormDataStore.getCurrentScreen().questions &&
        myFormDataStore.getCurrentScreen().questions.length > 0
        ? myFormDataStore.getCurrentScreen().questions.map((question) => false)
        : []
    );

    myFormDataStore.setVisitedStep(
      myFormDataStore.getCurrentSectionIndex(),
      myFormDataStore.getCurrentStepIndex(),
      true
    );
    myFormDataStore.setVisitedSection(
      myFormDataStore.getCurrentSectionIndex(),
      true
    );

    fireEvent(
      "pageLoad",
      {},
      {
        leadAppStage: "Medicare 101",
        leadAppSubStage: myFormDataStore.getLeadAppSubStage(),
        description: "M101 Question Changed",
      }
    );

    if (myFormDataStore.getCurrentScreen().id === "3.75.1") {
      setIsLoading(true)
      myFormDataStore.updateFormProgressForThankYouPage();
      updateSalesForceLead().then((data) => {
        setIsLoading(false)
      })
        .catch(() => {
          setIsLoading(false)
        })

      fireEvent("formSubmit", null, {
        progress: myFormDataStore.getFormProgessText(),
      });

      const timer = setTimeout(() => {
        fireEvent('saveLeadDetail', {}, {});
        updateSalesForceLead({
          leadAppStage: 'Medicare 101',
          callbackLogic: myFormDataStore.isThankYouPage()
            ? 'Completed M101 to Quotes'
            : 'Skipped M101 to Quotes',
          leadAppSubstage:
            myFormDataStore.getCurrentScreen().id +
            ' - ' +
            myFormDataStore.getCurrentStep().label,
        });
        routeToQuotingTool();
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, [
    myFormDataStore.getCurrentScreenIndex(),
    myFormDataStore.getCurrentStepIndex(),
    myFormDataStore.getCurrentSectionIndex(),
  ]);

  const qp = getQueryParamsAsKeyValuePairs();

  useEffect(() => {
    qp.spouse === "yes" &&
      myFormDataStore.makeSpouseSupplementalQuestionsVisible();
  }, []);

  function createMarkup(str) {
    return {
      __html: str,
    };
  }

  useEffect(() => {
    const changedAnswerIndices =
      myFormDataStore.toggleQuestionsVisibility(answers);
    changedAnswerIndices &&
      changedAnswerIndices.forEach((index) => {
        updateAnswer(index, "");
      });
  }, [answers]);

  function updateShowValidationError(index, newValue) {
    const newArray = [...showValidationErrors];
    newArray[index] = newValue;
    setShowValidationErrors(newArray);
  }

  function updateAnswer(index, newValue) {
    if(newValue.includes('No Preferred Carrier')){
      const newArray = [...answers];
      newArray[index] = ['No Preferred Carrier'];
      setAnswers(newArray)
    }
    else {
      const newArray = [...answers];
      newArray[index] = newValue;
      setAnswers(newArray);
    }
  }
  function updateChipText(index, newValue) {
    const newArray = [...answers];
    newArray[index] = newValue;
    setChipTexts(newArray);
  }

  function isAnswerValid(answer, questionType) {
    // Check if string is empty or contains only spaces
    if (!answer || /^\s*$/.test(answer)) return false;

    if (Array.isArray(answer)) {
      // If answer is an array, check if every element is valid as per the questionType
      return answer.every((item) => isAnswerValid(item, questionType));
    }

    const dateRegex = /^[a-zA-Z]+-\d{4}$/; // regex to match <month>-<year> format
    const dropDownRegex = /^(?:\S+\s*)+\s*$/;
    const chipsRegex = /^(?=.*[a-zA-Z])[a-zA-Z,\s()-]*$/; // Check if string contains only a-z, A-Z, commas and spaces
    let regex = /^(?:\w+\s*)+\s*$/; // regex to match words with spaces
    if (questionType === QuestionType.Date) regex = dateRegex;
    if (questionType === QuestionType.DropdownBox) regex = dropDownRegex;
    if (questionType === QuestionType.CheckBox) regex = dropDownRegex;
    if (questionType === QuestionType.Chips || questionType === QuestionType.MultiDropdownBox) regex = chipsRegex;
    return regex.test(answer);
  }

  function arraysMatch(array1, array2) {
    // Check if the arrays have the same length
    if (array1.length !== array2.length) {
      return false;
    }

    // Compare each element of the arrays
    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    // All elements match
    return true;
  }

  const handleSubmit = () => {
    let isFormValid = true;
    setIsInvalid(false)
    let updatedAnswers = [...answers]; // create a copy of answers to update
    if (
      myFormDataStore.getCurrentScreen().questions &&
      myFormDataStore.getCurrentScreen().questions.length > 0
    ) {
      const validationErrors = [];
      for (let index in answers) {
        let isAnswerValidBool = myFormDataStore.getCurrentScreen().questions[
          index
        ].isVisible
          ? isAnswerValid(
              answers[index],
              myFormDataStore.getCurrentScreen().questions[index].questionType
            )
          : true;

        if (
          myFormDataStore.getCurrentScreen().questions[index].questionType ===
          QuestionType.Chips
        ) {
          // check if valid chip text exists
          if (chipTexts.length !== 0 && chipTexts[2] !== "") {
            if (isAnswerValid(chipTexts[index], QuestionType.Chips)) {
              updatedAnswers[index] = [...answers[index], chipTexts[index]]; // update the answer
              updateChipText(index, ""); // clear the chip text
              isAnswerValidBool = true;
            }
            else {
              isAnswerValidBool = false
              setIsInvalid(true)
            }
          }
        }
        validationErrors.push(!isAnswerValidBool);
        isFormValid = isFormValid && isAnswerValidBool;
      }
      setShowValidationErrors(validationErrors);
      !arraysMatch(answers, updatedAnswers) && setAnswers(updatedAnswers);
    }
    if (isFormValid) {
      handleScrollToTopSection("smooth");
      myFormDataStore.newHandleSubmit(updatedAnswers); // call newHandleSubmit with the updated answers
    }
  };

  return (
    !myFormDataStore.getCurrentStep().hideQuestionBox && (
      <div style={{ width: "100%", boxSizing: "border-box" }}>
        <Container>
          <br />
          <Typography
            textAlign="left"
            variant="h7"
            color="primary"
            fontWeight="bold"
          >
            {myFormDataStore.getCurrentStep().label}
          </Typography>
          {!myFormDataStore.getCurrentStep().isInfo && (
            <>
              <Typography
                textAlign="left"
                fontSize={themes(brand).typography.myFontSizes.h3}
                fontWeight={500}
              >
                {myFormDataStore.getCurrentScreen().title}
              </Typography>
              {myFormDataStore
                .getCurrentScreen()
                .questions // .questions.filter((question) => {
                //   return question.isVisible;
                // })
                .map((question, index) => {
                  return question.isVisible ? (
                    <>
                      <Typography
                        textAlign="left"
                        variant="h7"
                        color="greys.dark"
                        key={question.id}
                      >
                        <div
                          dangerouslySetInnerHTML={createMarkup(question.title)}
                          style={{ marginTop: "10px" }}
                        />
                      </Typography>
                      <br />
                      <ErrorBoundary fallback={<p>Something went wrong</p>}>
                        <AnswerDiv>
                          {question.questionType === QuestionType.CheckBox && (
                            <McqAnswer
                              options={question.checkBox.options}
                              value={answers[index]}
                              handleChange={(newValue) => {
                                updateAnswer(index, newValue);
                                updateShowValidationError(index, false);
                              }}
                              showError={showValidationErrors[index]}
                              key={question.id}
                            />
                          )}
                          {question.questionType === QuestionType.DropdownBox &&
                            (!answers[index] ||
                              question.dropDown.options.includes(
                                answers[index]
                              )) && (
                              <DropDownAnswer
                                options={question.dropDown.options}
                                width={windowWidth < 400 ? '120px' : '200px'}
                                key={question.id}
                                label="Your Answer"
                                value={answers[index]}
                                handleChange={(value) => {
                                  updateAnswer(index, value);
                                  updateShowValidationError(index, false);
                                }}
                                color="primary"
                                allowOtherValues={false}
                                showValidationError={
                                  showValidationErrors[index]
                                }
                              />
                            )}
                          {question.questionType === QuestionType.Date && (
                            <DateAnswer
                              value={answers[index]}
                              key={question.id}
                              handleChange={(date) => {
                                updateAnswer(index, date);
                              }}
                              showValidationError={showValidationErrors[index]}
                              setValidationErrorFalse={() => {
                                updateShowValidationError(index, false);
                              }}
                            />
                          )}
                          {question.questionType === QuestionType.Chips &&
                            Array.isArray(answers[index]) && (
                              <ChipsAnswer
                                chips={answers[index]}
                                handleChipsChange={(chips) => {
                                  updateAnswer(index, chips);
                                  updateShowValidationError(index, false);
                                }}
                                // options={options}
                                showError={showValidationErrors[index]}
                                chipsText={chipTexts[index]}
                                setChipsText={(newValue) => {
                                  updateChipText(index, newValue);
                                  updateShowValidationError(index, false);
                                }}
                                isInvalid={isInvalid}
                                limit={question.limit}
                              />
                            )}
                          {question.questionType ===
                            QuestionType.MultiDropdownBox && (
                            <MultiPickDropDownAnswer
                              color="primary"
                              selectedOptions={answers[index]}
                              options={question.dropDown.options}
                              setSelectedOptions={(newCarriers) => {
                                updateAnswer(index, newCarriers);
                                updateShowValidationError(index, false);
                              }}
                              showError={showValidationErrors[index]}
                              limit={question.limit}
                            />
                          )}
                        </AnswerDiv>
                      </ErrorBoundary>
                    </>
                  ) : null;
                })}
            </>
          )}

          <br />
          <ScrollButtonContainer
            onClick={() => handleScrollToTopSection("smooth")}
          >
            <FaArrowUp style={{ color: "#222c69" }} />
          </ScrollButtonContainer>
        </Container>
        <br />
        <SectionContainer>
          {myFormDataStore.getCurrentScreen().id !== "1.05.1" && (
            <ArrowButton
              sx={{
                width: "100%",
                maxWidth: "200px",
                textTransform: "unset !important",
              }}
              // sx={{ translate: "-75px 0px" }}
              // sx={{ position: "absolute", left: "8px" }}
              variant="outlined"
              onClick={() => {
                const indices = myFormDataStore.previousScreen();
                indices[0] !== myFormDataStore.getCurrentSectionIndex() &&
                  myFormDataStore.toggleAccordionSection(indices[0]);
                myFormDataStore.updateIndices(
                  indices[0],
                  indices[1],
                  indices[2]
                );
              }}
            >
              <NavigateBeforeIcon
                id={`${(myFormDataStore.getCurrentSectionIndex() !==
                  myFormDataStore.previousScreen()[0] ||
                myFormDataStore.getCurrentStepIndex() !==
                  myFormDataStore.previousScreen()[1]
                  ? "Previous Section"
                  : "Previous Question"
                )
                  .replace(/\s/g, "-")
                  .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
              />
              <Typography
                fontWeight="bold"
                variant="h7"
                id={`${(myFormDataStore.getCurrentSectionIndex() !==
                  myFormDataStore.previousScreen()[0] ||
                myFormDataStore.getCurrentStepIndex() !==
                  myFormDataStore.previousScreen()[1]
                  ? "Previous Section"
                  : "Previous Question"
                )
                  .replace(/\s/g, "-")
                  .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
              >
                Previous
                {myFormDataStore.getCurrentSectionIndex() !==
                  myFormDataStore.previousScreen()[0] ||
                myFormDataStore.getCurrentStepIndex() !==
                  myFormDataStore.previousScreen()[1]
                  ? " Section"
                  : " Question"}
              </Typography>
            </ArrowButton>
          )}
          {!myFormDataStore.isThankYouPage() && (
            <ArrowButton
              sx={{
                width: "100%",
                maxWidth: "200px",
                textTransform: "unset !important",
                // ...(myFormDataStore.getCurrentScreen().id !== "1.05.1" && {
                //   translate: "-8px 0px",
                // }),
                marginTop: windowWidth<800 && '20px',
              }}
              variant="contained"
              onClick={() => {
                if (!myFormDataStore.isFirstQuestionGettingSaved())
                  handleSubmit();
              }}
            >
              {myFormDataStore.isFirstQuestionGettingSaved() ? (
                <CircularProgress color="inherit" disableShrink size={20} />
              ) : (
                <>
                  <Typography
                    fontWeight="bold"
                    variant="h7"
                    id={`${(myFormDataStore.isThankYouPage()
                      ? "Submit"
                      : myFormDataStore.getCurrentScreen().questions.length ===
                        0
                      ? "Next Section"
                      : myFormDataStore.getCurrentScreenIndex() <
                        myFormDataStore
                          .getCurrentStep()
                          .screens.filter((s) => s.isVisible).length -
                          1
                      ? "Next Question"
                      : "Next Section"
                    )
                      .replace(/\s/g, "-")
                      .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                  >
                    {myFormDataStore.isThankYouPage()
                      ? "Submit"
                      : myFormDataStore.getCurrentScreen().questions.length ===
                        0
                      ? "Next Section"
                      : myFormDataStore.getCurrentScreenIndex() <
                        myFormDataStore
                          .getCurrentStep()
                          .screens.filter((s) => s.isVisible).length -
                          1
                      ? "Next Question"
                      : "Next Section"}
                  </Typography>
                  <NavigateNextIcon
                    id={`${(myFormDataStore.isThankYouPage()
                      ? "Submit"
                      : myFormDataStore.getCurrentScreen().questions.length ===
                        0
                      ? "Next Section"
                      : myFormDataStore.getCurrentScreenIndex() <
                        myFormDataStore
                          .getCurrentStep()
                          .screens.filter((s) => s.isVisible).length -
                          1
                      ? "Next Question"
                      : "Next Section"
                    )
                      .replace(/\s/g, "-")
                      .toLowerCase()}-arrow-button-${myFormDataStore.getButtonId()}`}
                  />
                </>
              )}
            </ArrowButton>
          )}
        </SectionContainer>
      </div>
    )
  );
}

export default observer(Question);
