// import styled from "@emotion/styled";
import emotionStyled from "@emotion/styled";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { StepState } from "../../../data/enums.ts";
import getThemeName from "../../../hooks/useThemeFromUrl";
import { ReactComponent as RadioEmptyIcon } from "../../../icons/radio_empty.svg";
import { myFormDataStore } from "../../../stores/FormDataStore.tsx";
import themes from "../../../themes";
import RadioCheck from "../../icons/RadioCheck";
import RadioCurrent from "../../icons/RadioCurrent";
import "./stepper.css";

const brand = getThemeName();

const ColorlibStepIconRoot = emotionStyled("div")(({ theme, ownerState }) => ({
  display: "flex",
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  let icon;
  if (active)
    icon = <RadioCurrent color={themes(brand).palette.primary.main} />;
  else if (completed)
    icon = <RadioCheck color={themes(brand).palette.primary.main} />;
  else icon = <RadioEmptyIcon />;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icon}
    </ColorlibStepIconRoot>
  );
}

const Container = emotionStyled.div`
  max-width: 400px;
  padding: 0px 20px;

  .MuiStepLabel-alternativeLabel {
    flex-direction: row;
  }
`;

const StyledStep = styled(Step)(() => ({
  "& .MuiStepLabel-alternativeLabel": {
    flexDirection: "row !important",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginLeft: "2.5px",
    textAlign: "left",
    gap: "0.5rem",
  },
}));

function MyStepper({ sectionIndex }) {
  const handleOnClick = (stepIndex) => {
    myFormDataStore.handleClickStep(sectionIndex, stepIndex);
  };
  return (
    <Container>
      <Stepper
        alternativeLabel
        activeStep={
          myFormDataStore.accordionData[sectionIndex].currentStepIndex
        }
        orientation="vertical"
      >
        {myFormDataStore.accordionData[sectionIndex].steps
          .filter((step) => step.isVisible)
          .map((step, index) => (
            <StyledStep
              key={step.label}
              completed={step.stepState === StepState.Completed}
              //active={step.stepState === StepState.WIP}
              active={
                sectionIndex === myFormDataStore.getCurrentSectionIndex() &&
                myFormDataStore.getCurrentStepIndex() === index
              }
              onClick={() => {
                handleOnClick(index);
              }}
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  color={
                    step.stepState === StepState.Pending ? `grey` : `black`
                  }
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}></Box>
              </StepContent>
            </StyledStep>
          ))}
      </Stepper>
    </Container>
  );
}

export default observer(MyStepper);
