// import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import "./App.css";
import convertDateString from "./components/questionPage/convertDateString";
import { ActionContextProvider, createConfig, fireEvent } from "./cra";
import getLeadId from "./hooks/getLeadIdFromUrl";
import {
  fetchUserNameFromUrl,
  getQueryParamsAsKeyValuePairs,
  fetchIsUATFromUrl,
} from "./hooks/queryParams";
import getThemeName from "./hooks/useThemeFromUrl";
import HomePage from "./pages/home";
import FormService from "./services/FormService";
import { myFormDataStore } from "./stores/FormDataStore";
import themes from "./themes";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER
    ? process.env.REACT_APP_GTM_CONTAINER
    : "GTM-WHC677S",
};

TagManager.initialize(tagManagerArgs);

createConfig({
  website: "Medicare 101",
  idleTimer: [],
});

function App() {
  const brand = getThemeName();
  const qp = getQueryParamsAsKeyValuePairs();
  let callbackStatus = null;
  let callbackPercentage = 1;

  // State : {spouse, year, month, day, spouse_year, spouse_month, spouse_day, gender}
  // qp : {postalCode}
  // leadCreatedData : {salesforceID, username, passthroughCode}
  // callinnum
  const decideUrl = () => {
    let state = {
      spouse: qp.spouse ? qp.spouse : "",
      // year: "01",
      // month: "12",
      // day: "14",
      // spouse_year: "01",
      // spouse_month: "12",
      // spouse_day: "14",
      gender: qp.gender ? qp.gender : "",
      stateAbbrv: qp.state ? qp.state : "",
    };

    let callInNum = qp.callinnum ? qp.callinnum : "";
    let leadCreatedData = {
      salesforceID: qp.lead_id ? qp.lead_id : "",
      username: qp.username ? qp.username : "",
      passthroughCode: qp.token ? qp.token : "",
    };

    let spouseOfAge = false;
    let hasSpouse = state.spouse === "yes" ? true : false;

    const urlPath = window.location.pathname;
    let pc = "";
    const params = new URLSearchParams(window.location.search);
    const brandName = params.has("leadBrand") ? params.get("leadBrand") : ''
    if (params.has("type")) {
      pc = params.get("type");
      
      // For me leads, the coverage will be Medicare Supplement in quoting tool
      if(pc === 'me') {
        pc = 'ms'
      }
    }

    if (
      myFormDataStore.getFirstCoveragePreference() ===
      "Option 1 - Medicare Advantage (Part C)"
    )
      pc = "ma";
    else if (
      myFormDataStore.getFirstCoveragePreference() ===
      "Option 2 - Medicare Supplement (Medigap)"
    )
      pc = "ms";

    if (
      myFormDataStore.getConfirmedCoveragePreference() ===
      "Medicare Advantage (Part C)"
    )
      pc = "ma";
    else if (
      myFormDataStore.getConfirmedCoveragePreference() ===
      "Medicare Supplement (Medigap)"
    )
      pc = "ms";

    let query = `?pc=${pc}&spouse=${state.spouse}&lead_id=${
      leadCreatedData.salesforceID
    }&callinnum=${callInNum}&zip=${qp.zip}&state=${
      state.stateAbbrv
    }&gender=${state.gender.charAt(0).toUpperCase()}&username=${
      leadCreatedData.username
    }&token=${leadCreatedData.passthroughCode}`;

    if (qp["_gl"]) query += `&_gl=${qp["_gl"]}`;
    query += '&nxt=QT-LOGIN'
    query += `&leadBrand=${brandName}`

    if (qp["cnfg"]) query += `&cnfg=${qp["cnfg"]}`;

    return process.env.REACT_APP_REDIRECT_APP_URL + query;
    // }
  };
  function routeToQuotingTool() {
    
    const redirectURL = decideUrl();

    fireEvent(
      'navigate',
      {
        target: { href: redirectURL },
      },
      {
        description: 'M101 QT Navigation',
      }
    );
    fireEvent("saveLeadDetail", {}, {});
    console.log("decided url to redirect: ", redirectURL);
    setTimeout(() => {
      if (redirectURL.startsWith("https") || redirectURL.startsWith("http"))
        window.location.href = redirectURL;
      else window.location.href = redirectURL;
    }, 1000)
  }

  const updateCallbackStatus = () => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("callback_status")) {
      if (params.get("callback_status") === "") {
        callbackStatus = null;
      } else {
        callbackStatus = params.get("callback_status");
      }
    }
    if (params.has("callback_percentage")) {
      if (params.get("callback_percentage") === "") {
        callbackPercentage = 1;
      } else {
        callbackPercentage = parseInt(params.get("callback_percentage"));
      }
    }
    if (callbackStatus == "LQ Not in Dial Queue") {
      const randomNum = Math.floor(Math.random() * 100) + 1;
      if (randomNum <= callbackPercentage) {
        callbackStatus = "LQ Added to Dial Queue";
      }
    }
    return callbackStatus;
  };

  async function idleTimeCallbackM101() {
    if(myFormDataStore.getM101ObjectName() === "") {
      const persistedM101Object = localStorage.getItem(getLeadId());
      if (persistedM101Object !== null) {
        myFormDataStore.setM101ObjectName(persistedM101Object);
      }
    }
    const medicarePlanBCurrentDate = convertDateString(
      myFormDataStore.getMedicarePlanBCurrentDate()
    );
    const spouseMedicarePlanBCurrentDate = convertDateString(
      myFormDataStore.getSpouseMedicarePlanBCurrentDate()
    );
    const data = {
      leadId: getLeadId() ? getLeadId() : "",
      userName: fetchUserNameFromUrl() ? fetchUserNameFromUrl() : "",
      m101Name: myFormDataStore.getM101ObjectName()
        ? myFormDataStore.getM101ObjectName()
        : "",
      Carrier_Preference__c: myFormDataStore.getCarrierPreference().join(";")
        ? myFormDataStore.getCarrierPreference().join(";")
        : "",
      Medicare_Part_B_Date_Current__c: medicarePlanBCurrentDate,

      medicare101VideoProgress: myFormDataStore.getFormProgessText()
        ? myFormDataStore.getFormProgessText()
        : "",
      currentCoverageSelf: myFormDataStore.getInsuranceType()
        ? myFormDataStore.getInsuranceType()
        : "",
      currentPlanTypeSelf: myFormDataStore.getPlanType()
        ? myFormDataStore.getPlanType()
        : "",
      currentProviderSelf: myFormDataStore.getProvider()
        ? myFormDataStore.getProvider()
        : '',
      Medicare_Part_B_Date_Current__c_spouse: spouseMedicarePlanBCurrentDate,
      currentCoverageSpouse: myFormDataStore.getSpouseInsuranceType()
        ? myFormDataStore.getSpouseInsuranceType()
        : "",
      currentPlanTypeSpouse: myFormDataStore.getSpousePlanType()
        ? myFormDataStore.getSpousePlanType()
        : "",
      currentProviderSpouse: myFormDataStore.getSpouseProvider()
        ? myFormDataStore.getSpouseProvider()
        : "",
      callbackStatus: qp.callbackStatus ? qp.callbackStatus : "",
      m101ConfirmedCoverage: myFormDataStore.getConfirmedCoveragePreference()
        ? myFormDataStore.getConfirmedCoveragePreference()
        : "",
      m101FirstCoverage: myFormDataStore.getFirstCoveragePreference()
        ? myFormDataStore.getFirstCoveragePreference()
        : "",
      forUAT: fetchIsUATFromUrl(),
    };

    if (myFormDataStore.getMedicarePlanBCurrentDate()) {
      data["Medicare_Part_B_Date_Current__c"] = convertDateString(
        myFormDataStore.getMedicarePlanBCurrentDate()
      );
    }
    if (data.callbackStatus !== "LQ Not in Dial Queue") {
      (data["leadAppStage"] = "Medicare 101"),
        (data["callbackLogic"] = "Timeout");
      data["leadAppSubstage"] = myFormDataStore.getLeadAppSubStage();
    }
    const result = await FormService.updateQTSalesforceLead(data);
    console.log('Salesforce lead updated during Idle time!');
    if (
      myFormDataStore.getM101ObjectName() === null ||
      myFormDataStore.getM101ObjectName() === undefined ||
      myFormDataStore.getM101ObjectName() === ''
    )
      myFormDataStore.setM101ObjectName(result.data.data.M101ObjectName);

  }
  function exitIntentCallback() {
    if(myFormDataStore.getM101ObjectName() === "") {
      const persistedM101Object = localStorage.getItem(getLeadId());
      if (persistedM101Object !== null) {
        myFormDataStore.setM101ObjectName(persistedM101Object);
      }
    }
    fireEvent("saveLeadDetail", {}, {});
    const data = {
      leadId: getLeadId() ? getLeadId() : "",
      userName: fetchUserNameFromUrl() ? fetchUserNameFromUrl() : "",
      m101Name: myFormDataStore.getM101ObjectName()
        ? myFormDataStore.getM101ObjectName()
        : "",
      Carrier_Preference__c: myFormDataStore.getCarrierPreference().join(";")
        ? myFormDataStore.getCarrierPreference().join(";")
        : "",
      Medicare_Part_B_Date_Current__c:
        myFormDataStore.getMedicarePlanBCurrentDate()
          ? convertDateString(myFormDataStore.getMedicarePlanBCurrentDate())
          : "",
      medicare101VideoProgress: myFormDataStore.getFormProgessText()
        ? myFormDataStore.getFormProgessText()
        : "",
      currentCoverageSelf: myFormDataStore.getInsuranceType()
        ? myFormDataStore.getInsuranceType()
        : "",
      currentPlanTypeSelf: myFormDataStore.getPlanType()
        ? myFormDataStore.getPlanType()
        : "",
      currentProviderSelf: myFormDataStore.getProvider()
        ? myFormDataStore.getProvider()
        : "",
      Medicare_Part_B_Date_Current__c_spouse:
        myFormDataStore.getSpouseMedicarePlanBCurrentDate()
          ? convertDateString(
              myFormDataStore.getSpouseMedicarePlanBCurrentDate()
            )
          : "",
      currentCoverageSpouse: myFormDataStore.getSpouseInsuranceType()
        ? myFormDataStore.getSpouseInsuranceType()
        : "",
      currentPlanTypeSpouse: myFormDataStore.getSpousePlanType()
        ? myFormDataStore.getSpousePlanType()
        : "",
      currentProviderSpouse: myFormDataStore.getSpouseProvider()
        ? myFormDataStore.getSpouseProvider()
        : "",
      callbackStatus: qp.callbackStatus ? qp.callbackStatus : "",
      m101ConfirmedCoverage: myFormDataStore.getConfirmedCoveragePreference()
        ? myFormDataStore.getConfirmedCoveragePreference()
        : "",
      m101FirstCoverage: myFormDataStore.getFirstCoveragePreference()
        ? myFormDataStore.getFirstCoveragePreference()
        : "",
      forUAT: fetchIsUATFromUrl(),
    };

    if (myFormDataStore.getMedicarePlanBCurrentDate()) {
      data["Medicare_Part_B_Date_Current__c"] = convertDateString(
        myFormDataStore.getMedicarePlanBCurrentDate()
      );
    }
    if (data.callbackStatus !== "LQ Not in Dial Queue") {
      (data["leadAppStage"] = "Medicare 101"),
        (data["callbackLogic"] = "Exit Intent");
      data["leadAppSubstage"] =
      myFormDataStore.getLeadAppSubStage()
    }
    FormService.updateQTSalesforceLead(data).then(() => {
      console.log("Salesforce lead updated during Exit intent!");
    });
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === "/") {
      const queryParams = window.location.search;
      const params = new URLSearchParams(window.location.search);
      const pc = params.has("type") ? params.get("type") : "/ms/";
      const newUrl = pc + (queryParams || "");
      navigate(newUrl);
    }

    fireEvent(
      "saveLeadDetail",
      {},
      {
        salesforceID: qp.lead_id,
        username: qp.username,
        passthroughCode: qp.token,
      }
    );
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={themes(brand)}>
        <ActionContextProvider
          someProp="some prop value"
          routeToQuotingTool={routeToQuotingTool}
          idleTimeCallbackM101={idleTimeCallbackM101}
          exitIntentCallback={exitIntentCallback}
        >
          <HomePage routeToQuotingTool={routeToQuotingTool} />
        </ActionContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
