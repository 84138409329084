function getQueryParamsFromString(queryParamsString) {
  let queryParams = [];

  if (queryParamsString)
    if (queryParamsString.includes("&"))
      queryParams = queryParamsString.split("&");
    else queryParams.push(queryParamsString);

  return queryParams;
}

function getKeyValuePairsFromQueryParams(queryParams) {
  let keyValuePairs = {};
  queryParams.forEach((queryParam) => {
    const [key, value] = queryParam.split("=");
    keyValuePairs[key] = value;
  });

  return keyValuePairs;
}

function fetchBrandFromUrl() {
  const queryParamsString = window.location.search.split("?")[1];
  const queryParams = getQueryParamsFromString(queryParamsString);
  const keyValuePairs = getKeyValuePairsFromQueryParams(queryParams);

  const brand = keyValuePairs["leadBrand"];
  return brand;
}

function fetchUserNameFromUrl() {
  const queryParamsString = window.location.search.split("?")[1];
  const queryParams = getQueryParamsFromString(queryParamsString);
  const keyValuePairs = getKeyValuePairsFromQueryParams(queryParams);

  const username = keyValuePairs["username"];
  return username;
}

function getQueryParamsAsKeyValuePairs(){
  const queryParamsString = window.location.search.split("?")[1];
  const queryParams = getQueryParamsFromString(queryParamsString);
  const keyValuePairs = getKeyValuePairsFromQueryParams(queryParams);
  return keyValuePairs;
}

function fetchIsUATFromUrl() {
  const queryParamsString = window.location.search.split("?")[1];
  const queryParams = getQueryParamsFromString(queryParamsString);
  const keyValuePairs = getKeyValuePairsFromQueryParams(queryParams);

  const forUAT = keyValuePairs["forUAT"] === 'true' ? true : false;
  return forUAT;
}

export {
  fetchBrandFromUrl,
  getKeyValuePairsFromQueryParams,
  getQueryParamsFromString,
  getQueryParamsAsKeyValuePairs,
  fetchUserNameFromUrl,
  fetchIsUATFromUrl
};
