import { createTheme } from "@mui/material/styles";

const medicareComparedTheme = createTheme({
  palette: {
    primary: {
      main: "#222c69",
    },
    secondary: {
      main: "#BA0000",
    },
    greys: {
      light: "#D2D2D1",
      dark: "#515151",
      primary1: "#FBFBFE",
      primary2: "#F2F7FE",
      text: "#737373",
    },
  },
  typography: {
    fontFamily: "sans-serif",
    myFontSizes: {
      h1: 40,
      h2: 30,
      h3: 24,
      h4: 20,
      h5: 16,
      h6: 12,
      h7: 8,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
  },

  border: {
    card: "2px solid #D2D2D1",
  },
});

const aetnaTheme = createTheme({
  palette: {
    primary: {
      main: "#7D3F98",
    },
    secondary: {
      main: "#BA0000",
    },
    greys: {
      light: "#D2D2D1",
      dark: "#515151",
      primary1: "#FBFBFE",
      primary2: "#F1EBF4",
      text: "#737373",
    },
  },
  typography: {
    fontFamily: "sans-serif",
    myFontSizes: {
      h1: 40,
      h2: 30,
      h3: 24,
      h4: 20,
      h5: 16,
      h6: 12,
      h7: 8,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 20,
    },
  },

  border: {
    card: "2px solid #D2D2D1",
  },
});

const themes = (theme) => {
  return medicareComparedTheme;
};

export default themes;
