import axios from "axios";
import axiosRetry from "axios-retry";

const fetchUrlFromCDN = (brand) => {
  const url = `${process.env.REACT_APP_CDN_URL}/?brand=${brand}`;
  return new Promise((resolve, reject) => {
    axiosRetry(axios, { retries: 3 });
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) resolve(response.data);
        else throw response;
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default fetchUrlFromCDN;
