import { Box, styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import ReactPlayer from "react-player";
import { ActionContext, fireEvent } from "../../cra";
import getThemeName from "../../hooks/useThemeFromUrl";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";
import themes from "../../themes";

const brand = getThemeName();

const Container = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  background-color: ${themes(brand).palette.greys.primary1};
  border: ${themes(brand).border.card};
  //padding: "30px 80px",
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* @media screen and (max-width: 400px) {
    height: 200px;
  } */
`;

function VideoSection() {
  const { trackVideo } = React.useContext(ActionContext);
  const params = new URLSearchParams(window.location.search);
  const brandName = params.has("leadBrand") ? params.get("leadBrand") : "";
  const videoURL =
    brandName === "aetna-medicare"
      ? myFormDataStore.getCurrentStep().aetnaVideoUrl
      : brandName === "mutual of omaha-medicare"
      ? myFormDataStore.getCurrentStep().mutualOfOmahaVideoUrl
      : brandName === "medicareassociation"
      ? myFormDataStore.getCurrentStep().medicareassociationVideoUrl
      : myFormDataStore.getCurrentStep().videoUrl;

  useEffect(() => {
    videoURL &&
      fireEvent(
        "videoLoaded",
        {},
        { value: myFormDataStore.getCurrentStep().label }
      );
  }, [videoURL]);

  return videoURL === null || videoURL === undefined ? null : (
    <Container>
      <ReactPlayer
        url={videoURL}
        controls={true}
        playing={true}
        volume={1}
        muted={false}
        width="100%"
        config={{
          wistia: {
            options: {
              playerColor: themes(brand).palette.primary.main,
            },
          },
        }}
        onPlay={(e) => {
          trackVideo.startTimer(myFormDataStore.getCurrentStep().label);
        }}
        onPause={(e) => {
          trackVideo.clearTimer();
        }}
        onProgress={(e) => {
          trackVideo.trackSecondPlaying(e.playedSeconds);
        }}
      />
    </Container>
  );
}

export default observer(VideoSection);
