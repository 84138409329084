import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useEffect, useState } from "react";
import { myFormDataStore } from "../../stores/FormDataStore.tsx";

function McqAnswer({ options, value, handleChange, showError }) {
  const question = myFormDataStore.getCurrentQuestion();

  const [currentVal, setCurrentVal] = useState(question.answer);

  const onChange = (event) => {
    setCurrentVal(event.target.value);
    handleChange(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={currentVal}
        onChange={onChange}
        error={showError}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option}
            value={option}
            control={<Radio />}
            label={option}
            sx={{ textAlign: "left" }}
          />
        ))}
      </RadioGroup>
      {showError && (
        <span style={{ color: "red" }}>Please choose an option</span>
      )}
    </FormControl>
  );
}

export default McqAnswer;
